import React, { Fragment, useState, useEffect } from "react";
import validator from "validator";
import MetaData from "../../utils/MetaData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Input from "../inputs/Input";
import classes from "./MentorSignUp.module.css";
import styles from "../inputs/Input.module.css";
import Button from "../UI/buttons/Button";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  let [searchParams] = useSearchParams();
  const [pwdShow, setPwdShow] = useState(false);
  const [confirmPwdShow, setConfirmPwdShow] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      validator.isStrongPassword(password, {
        pointsPerUnique: 0,
        pointsPerRepeat: 0,
      }) &&
      password === confirmPassword
    ) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [password, confirmPassword]);

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    await axios
      .post(
        `https://oddience.herokuapp.com/api/v1/password/reset/${searchParams.get(
          "token"
        )}`,
        {
          password,
          confirmPassword,
        }
      )
      .then((response) => {
        toast("Password successfully changed");
        setTimeout(() => {
          navigate(`/coach/login`);
        }, 4000);
      })
      .catch((err) => {
        if (err.response.data.message) {
          console.log(err.response.data);
          setLoading(false);
          return toast.error(err.response.data.message);
        }
      });
  };
  return (
    <Fragment>
      <MetaData title={"Set a new password."} />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
      />
      <main className={`${classes["sign-up-main"]}  ${classes.home}`}>
        <h1 style={{ marginBottom: "4rem" }}>Enter a new password</h1>
        <form className={classes.form} onSubmit={submitHandler}>
          <Input
            id="password"
            classes={styles["form-control"]}
            styleExt={{ marginBottom: "0" }}
            type={pwdShow ? "text" : "password"}
            disabled={false}
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
            required={true}
            children={
              pwdShow ? (
                <i className="far fa-eye"></i>
              ) : (
                <i className="far fa-eye-slash"></i>
              )
            }
            onClick={() => {
              if (pwdShow) {
                setPwdShow(false);
              } else {
                setPwdShow(true);
              }
            }}
          />
          <label
            htmlFor="password"
            style={{
              margin: "0.5rem 0 1rem 0",
              display: "block",
              width: "100%",
              fontSize: "0.6rem",
            }}
          >
            Your password must contain: <br />
            <ul style={{ listStyleType: "none" }}>
              <li>
                a least 8 characters.
                {password.length >= 8 ? (
                  <i className="text-success ms-2 fad fa-check-circle" />
                ) : (
                  <i className="text-danger ms-2 fad fa-times-circle" />
                )}
              </li>
              <li>
                a minimum of 1 lower case letter.
                {/[a-z]/.test(password) ? (
                  <i className="text-success ms-2 fad fa-check-circle" />
                ) : (
                  <i className="text-danger ms-2 fad fa-times-circle" />
                )}
              </li>
              <li>
                a minimum of 1 upper case letter.
                {/[A-Z]/.test(password) ? (
                  <i className="text-success ms-2 fad fa-check-circle" />
                ) : (
                  <i className="text-danger ms-2 fad fa-times-circle" />
                )}
              </li>
              <li>
                a minimum of 1 numeric character.
                {/[0-9]/.test(password) ? (
                  <i className="text-success ms-2 fad fa-check-circle" />
                ) : (
                  <i className="text-danger ms-2 fad fa-times-circle" />
                )}
              </li>
              <li>
                {"a minimum of 1 special character."}
                {/[^A-Za-z0-9]/.test(password) ? (
                  <i className="text-success ms-2 fad fa-check-circle" />
                ) : (
                  <i className="text-danger ms-2 fad fa-times-circle" />
                )}
              </li>
            </ul>
          </label>
          <Input
            classes={styles["form-control"]}
            type={confirmPwdShow ? "text" : "password"}
            disabled={false}
            placeholder="Confirm Password"
            onChange={(e) => setConfirmPassword(e.target.value)}
            required={true}
            children={
              confirmPwdShow ? (
                <i className="far fa-eye"></i>
              ) : (
                <i className="far fa-eye-slash"></i>
              )
            }
            onClick={() => {
              if (confirmPwdShow) {
                setConfirmPwdShow(false);
              } else {
                setConfirmPwdShow(true);
              }
            }}
          />
          <Button
            styles={{
              width: "100%",
              backgroundColor: !formIsValid ? "gray" : "#03B1D8",
              cursor: !formIsValid ? "default" : "select",
            }}
            variant="primary"
            type={"submit"}
            text="Change password"
            loading={loading}
            disabled={!formIsValid}
            divStyles={{ margin: "1rem 0 0 0", width: "50%" }}
          />
        </form>
      </main>
    </Fragment>
  );
};

export default ForgotPassword;
