import axios from "axios";

const createUser = async (user) => {
  try {
    const response = await axios.post(
      "https://oddience.herokuapp.com/api/v1/user/new",
      user
    );
    return response.data;
  } catch (err) {
    return {error: err.response.data};
  }
};

export { createUser };
