import React from 'react';
import { Link } from 'react-router-dom';
import classes from './Header.module.css'

const Header = ({logoLink}) => {
  return (
    <header>
        <nav className={classes.header}>
            <Link to={logoLink}>
                <img className={classes.logo} src={`${process.env.PUBLIC_URL}/static/images/header-logo.png`} alt="Oddience Logo" />
            </Link>
        </nav>
    </header>
  )
}

export default Header