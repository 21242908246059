import React, { useEffect, useState } from "react";
import styles from "../inputs/Input.module.css";
import Select from "react-select";
import Button from "../UI/buttons/Button";

const stages = [
  { value: "Early Stage", label: "Early Stage" },
  { value: "Growth Stage", label: "Growth Stage" },
  { value: "Scale up", label: "Scale up" },
];

const SetCompanyStage = ({ onSubmit, loading }) => {
  const [stageSelected, setStageSelected] = useState("");
  const [stageLoading, setStageLoading] = useState(false);

  useEffect(() => {
    setStageLoading(loading);
  }, [loading]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // console.log(stageSelected)

    await onSubmit(stageSelected);

    setTimeout(() => {
      window.location.reload();
    }, 500);
  };
  return (
    <form onSubmit={handleFormSubmit}>
      <p>What company growth stage are you best suited for?</p>
      <Select
        defaultValue={stageSelected}
        onChange={setStageSelected}
        options={stages}
        isMulti={false}
        className={styles["form-select"]}
        // isDisabled={allSkills.length >= 5}
        isSearchable={true}
        value={stageSelected}
      />
      <Button loading={stageLoading} type={"submit"} text="Submit" />
    </form>
  );
};

export default SetCompanyStage;
