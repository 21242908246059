const availOptions = {
  mon: {
    startTime: 0,
    endTime: 0,
    
  },
  tue: {
    startTime: 0,
    endTime: 0,
    
  },
  wed: {
    startTime: 0,
    endTime: 0,
    
  },
  thu: {
    startTime: 0,
    endTime: 0,
    
  },
  fri: {
    startTime: 0,
    endTime: 0,
    
  },
  sat: {
    startTime: 0,
    endTime: 0,
    
  },
  sun: {
    startTime: 0,
    endTime: 0,
    
  },
};

const availReducer = (state, action) => {
  switch (action.type) {
    case "mon":
      return {
        ...state,
        mon: {
          startTime: action.payload.startTime,
          endTime: action.payload.endTime,
          
        }
      };
    case "tue":
      return {
        ...state,
        tue: {
          startTime: action.payload.startTime,
          endTime: action.payload.endTime,
          
        }
      };
    case "wed":
      return {
        ...state,
        wed: {
          startTime: action.payload.startTime,
          endTime: action.payload.endTime,
          
        }
      };
    case "thu":
      return {
        ...state,
        thu: {
          startTime: action.payload.startTime,
          endTime: action.payload.endTime,
          
        }
      };
    case "fri":
      return {
        ...state,
        fri: {
          startTime: action.payload.startTime,
          endTime: action.payload.endTime,
          
        }
      };
    case "sat":
      return {
        ...state,
        sat: {
          startTime: action.payload.startTime,
          endTime: action.payload.endTime,
          
        }
      };
    case "sun":
      return {
        ...state,
        sun: {
          startTime: action.payload.startTime,
          endTime: action.payload.endTime,
          
        }
      };
    default:
      return state;
  }
};

export {availOptions, availReducer};