import React, { Fragment, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Header from "../components/UI/Header";
import AdminLogin from "../components/admin/AdminLogin";
import AdminDashboard from "../components/admin/AdminDashboard";
import VerifySkills from "../components/admin/VerifySkills";
import Footer from "../components/UI/Footer";
import ProtectedRoute from "../utils/ProtectedRoute";
import Logout from "../components/admin/Logout";

const MentorRoutes = () => {
  const location = useLocation();
  return (
    <Fragment>
      <Header logoLink={location.pathname === "/admin/login" ? "/" : "#"} />
      <Routes>
        <Route
          path="/admin/login"
          element={
            <>
              <AdminLogin />
              <Footer />
            </>
          }
        />
        <Route
          path="/admin/dashboard"
          element={
            <>
              <ProtectedRoute>
                <Logout />
                <AdminDashboard />
                <Footer />
              </ProtectedRoute>
            </>
          }
        />
        <Route
          path="/admin/skills"
          element={
            <>
              <ProtectedRoute>
                <Logout />
                <VerifySkills />
                <Footer />
              </ProtectedRoute>
            </>
          }
        />
      </Routes>
    </Fragment>
  );
};

export default MentorRoutes;
