const timeOptions = [
  { value: "0", label: "12am" },
  { value: "0030", label: "12:30am" },
  { value: "0100", label: "1am" },
  { value: "0130", label: "1:30am" },
  { value: "0200", label: "2am" },
  { value: "0230", label: "2:30am" },
  { value: "0300", label: "3am" },
  { value: "0330", label: "3:30am" },
  { value: "0400", label: "4am" },
  { value: "0430", label: "4:30am" },
  { value: "0500", label: "5am" },
  { value: "0530", label: "5:30am" },
  { value: "0600", label: "6am" },
  { value: "0630", label: "6:30am" },
  { value: "0700", label: "7am" },
  { value: "0730", label: "7:30am" },
  { value: "0800", label: "8am" },
  { value: "0830", label: "8:30am" },
  { value: "0900", label: "9am" },
  { value: "0930", label: "9:30am" },
  { value: "1000", label: "10am" },
  { value: "1030", label: "10:30am" },
  { value: "1100", label: "11am" },
  { value: "1130", label: "11:30am" },
  { value: "1200", label: "12pm" },
  { value: "1230", label: "12:30pm" },
  { value: "1300", label: "1pm" },
  { value: "1330", label: "1:30pm" },
  { value: "1400", label: "2pm" },
  { value: "1430", label: "2:30pm" },
  { value: "1500", label: "3pm" },
  { value: "1530", label: "3:30pm" },
  { value: "1600", label: "4pm" },
  { value: "1630", label: "4:30pm" },
  { value: "1700", label: "5pm" },
  { value: "1730", label: "5:30pm" },
  { value: "1800", label: "6pm" },
  { value: "1830", label: "6:30pm" },
  { value: "1900", label: "7pm" },
  { value: "1930", label: "7:30pm" },
  { value: "2000", label: "8pm" },
  { value: "2030", label: "8:30pm" },
  { value: "2100", label: "9pm" },
  { value: "2130", label: "9:30pm" },
  { value: "2200", label: "10pm" },
  { value: "2230", label: "10:30pm" },
  { value: "2300", label: "11pm" },
  { value: "2330", label: "11:30pm" },
];

export default timeOptions;