import React, { Fragment, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import Header from "../components/UI/Header";
import Footer from "../components/UI/Footer";
import GetToKnow from "../components/signUp/GetToKnow";
import MentorSignUp from "../components/signUp/MentorSignUp";
import Login from "../components/signUp/Login";
import AlmostDone from "../components/signUp/AlmostDone";
import CreatePassword from "../components/signUp/CreatePassword";
import Success from "../components/signUp/Success";
import MentorDashboard from "../components/dashboards/MentorDashboard";
import MentorDashboardAdmin from "../components/dashboards/MentorDashboardAdmin";
// import Appointments from "../components/dashboards/onSched/Appointments";
import ProtectedRoute from "../utils/ProtectedRoute";
import ForgotPassword from "../components/signUp/ForgotPassword";

const MentorRoutes = () => {
  const location = useLocation();
  useEffect(() => {
    // console.log(location.pathname);
  }, [location.pathname]);
  return (
    <Fragment>
      <Header logoLink={location.pathname === "/coach/login" ? "/" : "#"} />
      <Routes>
        <Route
          path="/"
          element={
            <CSSTransition
              classNames={"slide-in"}
              // className="test"
              timeout={500}
              in={location.pathname === "/"}
            >
              <MentorSignUp />
            </CSSTransition>
          }
        />
        <Route path="/coach/login" element={<Login />} />
        <Route path="/password/forgot" element={<ForgotPassword />} />
        <Route
          path="/coach/sign-up/get-to-know"
          element={
            <ProtectedRoute>
              <CSSTransition
                classNames={"slide-in"}
                // className="test"
                timeout={500}
                in={location.pathname === "/coach/sign-up/get-to-know"}
              >
                <GetToKnow />
              </CSSTransition>
            </ProtectedRoute>
          }
        />
        <Route
          path="/coach/sign-up/almost-done"
          element={
            <ProtectedRoute>
              <AlmostDone />
            </ProtectedRoute>
          }
        />
        <Route
          path="/coach/sign-up/create-password"
          element={
            <ProtectedRoute>
              <CreatePassword />
            </ProtectedRoute>
          }
        />
        <Route
          path="/coach/sign-up/success"
          element={
            
              <Success />
           
          }
        />
        <Route
          path="/coach/:username"
          element={
            <Fragment>
              <MentorDashboard />
              <Footer />
            </Fragment>
          }
        />
        <Route
          path="/coach/dashboard"
          element={
            <Fragment>
              <MentorDashboardAdmin />
              <Footer />
            </Fragment>
          }
        />
        {/* <Route
          path="/admin/appointments"
          element={
            <Fragment>
              <Appointments />
              <Footer />
            </Fragment>
          }
        /> */}
      </Routes>
    </Fragment>
  );
};

export default MentorRoutes;
