import React from "react";
import timeRef from "../../utils/timeRef";
import Button from "../UI/buttons/Button";

const dayOptions = {
  mon: "Monday",
  tue: "Tuesday",
  wed: "Wednesday",
  thu: "Thursday",
  fri: "Friday",
  sat: "Saturday",
  sun: "Sunday",
};

const ShowOldAvailability = ({ prevAvailability, editing, timeOptions }) => {
  return (
    <div>
      <p>Here's your previously set availability.</p>
      <div style={{ fontSize: "0.86rem", padding: "0.8rem" }}>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            marginBottom: "1rem",
            fontWeight: "800",
          }}
        >
          <div style={{ width: "30%" }}>Day</div>
          <div style={{ width: "30%" }}>Start Time</div>
          <div style={{ width: "30%" }}>End Time</div>
        </div>
        {Object.keys(prevAvailability).map((day, i) => {
          return (
            <div
              key={i}
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                marginBottom: "0.5rem",
              }}
            >
              <div style={{ width: "30%" }}>{dayOptions[day]}</div>
              <div
                style={{
                  width: "30%",
                  color:
                    prevAvailability[day].startTime ===
                    prevAvailability[day].endTime
                      ? "red"
                      : "green",
                }}
              >
                {prevAvailability[day].startTime ===
                prevAvailability[day].endTime
                  ? "Unavailable"
                  : timeRef[prevAvailability[day].startTime]}
              </div>
              <div
                style={{
                  width: "30%",
                  color:
                    prevAvailability[day].startTime ===
                    prevAvailability[day].endTime
                      ? "red"
                      : "green",
                }}
              >
                {prevAvailability[day].startTime ===
                prevAvailability[day].endTime
                  ? "Unavailable"
                  : timeRef[prevAvailability[day].endTime]}
              </div>
            </div>
          );
        })}
      </div>
      <Button
        styles={{ width: "100%" }}
        variant="primary"
        type={"button"}
        text="Set new availability times"
        divStyles={{ margin: "0", width: "50%" }}
        onClick={editing}
      />
    </div>
  );
};

export default ShowOldAvailability;
