import React, { Fragment, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Input from "../inputs/Input";
import classes from "../signUp/MentorSignUp.module.css";
import styles from "../inputs/Input.module.css";
import Button from "../UI/buttons/Button";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const submitHandler = async (e) => {
    setLoading(true);
    e.preventDefault();

    await axios
      .post(`https://oddience.herokuapp.com/api/v1/admin/login`, {
        username: username.trim(),
        password,
      })
      .then((response) => {
        window.localStorage.setItem("token", response.data.token);

        setTimeout(() => {
          navigate(`/admin/dashboard`);
        }, 500);
      })
      .catch((err) => {
        if (err.response.data.message) {
          setLoading(false);
          return toast.error(err.response.data.message);
        }
      });
  };

  return (
    <Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
      />

      <main
        style={{ height: "calc(100vh - 17rem)" }}
        className={`${classes["sign-up-main"]}  ${classes.home}`}
      >
        <h1 style={{ marginBottom: "4rem" }}>Admin Login</h1>
        <form className={classes.form} onSubmit={submitHandler}>
          <Input
            classes={styles["form-control"]}
            type="text"
            disabled={false}
            placeholder="Username"
            onChange={(e) => setUsername(e.target.value)}
            required={true}
            value={username}
          />
          <Input
            classes={styles["form-control"]}
            type="password"
            disabled={false}
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
            required={true}
            value={password}
          />
          <Button text={"Sign in"} type="submit" loading={loading} />
        </form>
      </main>
    </Fragment>
  );
};

export default Login;
