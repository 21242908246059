import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import classes from "../dashboards/MentorDashboard.module.css";
import Button from "../UI/buttons/Button";
import { useNavigate } from "react-router-dom";
import capitalize from "../../helpers/capitalize"
import Filters from "./Filters";

const VerifySkills = () => {
  const [skills, setSkills] = useState(null);
  const [filter, setFilter] = useState("");
  const [pageLoading, setPageLoading] = useState(true);
  const [loading, setLoading] = useState({});
  const [skillIds, setSkillIds] = useState(null);
  const navigate = useNavigate();
  const fetch = async () => {
    setPageLoading(true);
    await axios
      .get(`https://oddience.herokuapp.com/api/v1/skills?filter=${filter}`, {
        headers: {
          token: window.localStorage.getItem("token"),
          role: "admin",
        },
      })
      .then((response) => {
        setPageLoading(false);
        setSkills(response.data.skills);
        setSkillIds(response.data.skillIds);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.message === "jwt expired") {
          navigate("/admin/login");
        }
      });
  };
  useEffect(() => {
    fetch();
  }, [filter]);

  const handleVerify = async (e) => {
    const skillId = e.target.dataset.skillId;
    let skillLoading = {};
    skillLoading[`${skillId}-${e.target.dataset.action}`] = true;
    setLoading(skillLoading);

    await axios
      .put(
        `https://oddience.herokuapp.com/api/v1/skills/update/${skillId}?action=${e.target.dataset.action}`,
        {},
        {
          headers: {
            token: window.localStorage.getItem("token"),
            role: "admin",
          },
        }
      )
      .then(async (response) => {
        skillLoading[`${skillId}-${e.target.dataset.action}`] = false;
        setLoading(skillLoading);
        if (e.target.dataset.action === "accept") {
          toast(
            `Successfully verified "${e.target.dataset.skill}" and it has been added to the database of active skills`
          );
        } else {
          toast(
            `Successfully rejected "${e.target.dataset.skill}" and it has not been added to the database of active skills`
          );
        }
        setTimeout(() => {
          fetch();
        }, 500);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const filterChangeHandle = (value) => {
    setFilter(value);
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
      />
      <div
        style={{ height: "fit-content", marginTop: "1rem" }}
        className={classes["container-main"]}
      >
        <h1 style={{ marginBottom: "1rem", marginTop: "1rem" }}>
          Verify Skills
        </h1>
        <h3 style={{ width: "100%", marginLeft: "4rem" }}>Filters</h3>
        <Filters
          style={{
            marginBottom: "2rem",
            marginTop: "1rem",
            padding: "0 2rem",
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
          onChange={filterChangeHandle}
        />

        {pageLoading ? (
          <div className={classes["page-loading"]}>
            <img
              src={`${process.env.PUBLIC_URL}/static/images/header-logo.png`}
              alt="Page loading indicator"
            ></img>
          </div>
        ) : (
          skills.map((skill) => {
            return (
              <div
                key={skill._id}
                style={{
                  marginBottom: "3rem",
                  width: "85%",
                  padding: "2rem",
                  boxShadow: "0px 9px 15px -12px #03b1d8",
                }}
              >
                <p>{capitalize(skill.skill)}</p>
                <p>Created by - {skill.createdBy}</p>
                <p>
                  Date Created -{" "}
                  {new Date(skill.dateCreated).toLocaleDateString()}
                </p>
                <p>
                  Status -{" "}
                  <span
                    style={{
                      color:
                        skill.status === "active"
                          ? "green"
                          : skill.status === "rejected"
                          ? "red"
                          : "black",
                    }}
                  >
                    {skill.status}
                  </span>
                </p>
                <div style={{ display: "flex" }}>
                  {skill.status !== "active" && (
                    <Button
                      data-skill-id={skill._id}
                      data-skill={skill.skill}
                      data-action="accept"
                      type="button"
                      text="Accept"
                      onClick={handleVerify}
                      divStyles={{ marginRight: "2rem", maxWidth: "10rem" }}
                      variant="primary"
                      loading={loading[`${skill._id}-accept`]}
                    />
                  )}
                  {skill.status !== "rejected" && (
                    <Button
                      data-skill-id={skill._id}
                      data-skill={skill.skill}
                      data-action="reject"
                      type="button"
                      text="Reject"
                      divStyles={{ maxWidth: "10rem" }}
                      styles={{ backgroundColor: "#e94242" }}
                      onClick={handleVerify}
                      variant="primary"
                      loading={loading[`${skill._id}-reject`]}
                    />
                  )}
                </div>
              </div>
            );
          })
        )}
      </div>
    </>
  );
};

export default VerifySkills;
