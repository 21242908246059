import React, { Fragment } from "react";
import { useLinkedIn } from "react-linkedin-login-oauth2";

const SignUpWithLinkedIn = () => {
  const { linkedInLogin } = useLinkedIn({
    clientId: "77ir10c4b9oj95",
    redirectUri: `https://app.oddience.co`,
    scope: "r_liteprofile r_emailaddress",
    onSuccess: (code) => {
      console.log(code);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const clickHandler = async () => {
    linkedInLogin();

    window.localStorage.clear();
  };
  return (
    <Fragment>
      <img
        onClick={clickHandler}
        src={`${process.env.PUBLIC_URL}/static/images/linkedInSignIn.png`}
        alt="Sign in with Linked In"
        style={{ maxWidth: "180px", cursor: "pointer" }}
        className="linkedIn"
      />
    </Fragment>
  );
};

export default SignUpWithLinkedIn;
