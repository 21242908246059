const timeRef = {
    0: '12:00am',
    30: '12:30am',
    100: '1:00am',
    130: '1:30am',
    200: '2:00am',
    230: '2:30am',
    300: '3:00am',
    330: '3:30am',
    400: '4:00am',
    430: '4:30am',
    500: '5:00am',
    530: '5:30am',
    600: '6:00am',
    630: '6:30am',
    700: '7:00am',
    730: '7:30am',
    800: '8:00am',
    830: '8:30am',
    900: '9:00am',
    930: '9:30am',
    1000: '10:00am',
    1030: '10:30am',
    1100: '11:00am',
    1130: '11:30am',
    1200: '12:00pm',
    1230: '12:30pm',
    1300: '1:00pm',
    1330: '1:30pm',
    1400: '2:00pm',
    1430: '2:30pm',
    1500: '3:00pm',
    1530: '3:30pm',
    1600: '4:00pm',
    1630: '4:30pm',
    1700: '5:00pm',
    1730: '5:30pm',
    1800: '6:00pm',
    1830: '6:30pm',
    1900: '7:00pm',
    1930: '7:30pm',
    2000: '8:00pm',
    2030: '8:30pm',
    2100: '9:00pm',
    2130: '9:30pm',
    2200: '10:00pm',
    2230: '10:30pm',
    2300: '11:00pm',
    2330: '11:30pm',
}

export default timeRef