import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./MentorSignUp.module.css";
import MetaData from "../../utils/MetaData";
import styles from "../inputs/Input.module.css";
import Input from "../inputs/Input";
import Button from "../UI/buttons/Button";
// import { Recorder } from "react-voice-recorder";
// import "react-voice-recorder/dist/index.css";

const GetToKnow = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [savedDetails] = useState(
    JSON.parse(window.localStorage.getItem("details"))
  );
  // const [audioDetails, setAudioDetails] = useState({
  //   url: null,
  //   blob: null,
  //   chunks: null,
  //   duration: {
  //     h: 0,
  //     m: 0,
  //     s: 0,
  //   },
  // });

  const [bio, setBio] = useState("");
  const navigate = useNavigate();

  useEffect(() => {

    if (savedDetails.bioData) {
      setFirstName(savedDetails.bioData.firstName);
      setLastName(savedDetails.bioData.lastName);
      setBio(savedDetails.bioData.bio);
    }
  }, [savedDetails.bioData]);

  // const handleAudioStop = (data) => {
  //   console.log(data);
  //   setAudioDetails(data);
  // };

  // function handleAudioUpload(file) {
  //   console.log(file);
  // }

  // function handleCountDown(data) {
  //   console.log(data);
  // }

  // function handleReset() {
  //   setAudioDetails({
  //     url: null,
  //     blob: null,
  //     chunks: null,
  //     duration: {
  //       h: 0,
  //       m: 0,
  //       s: 0,
  //     },
  //   });
  // }

  const submitHandler = (e) => {
    e.preventDefault();

    const details = JSON.parse(window.localStorage.getItem("details"));

    window.localStorage.setItem(
      "details",
      JSON.stringify({
        ...details,
        bioData: {
          firstName,
          lastName,
          bio,
        },
      })
    );

    setTimeout(() => {
      navigate("/coach/sign-up/almost-done");
    }, 500);
  };
  return (
    <Fragment>
      <MetaData title={'Let your mentees know more about you'}/>
      <Button
        text={
          <span>
            <i className="fas fa-arrow-left"></i> Back
          </span>
        }
        type="button"
        variant="plain"
        onClick={() => navigate("/")}
      />
      {/* <CSSTransition classNames={"slide-in"} timeout={500} in={inProp}> */}
        <main className={`${classes["sign-up-main"]} ${classes.wide}`}>
          <h1>Let Mentees Know Who You Are</h1>
          <form className={classes.form} onSubmit={submitHandler}>
            <Input
              classes={styles["form-control"]}
              type="text"
              disabled={false}
              placeholder="First Name"
              onChange={(e) => setFirstName(e.target.value)}
              required={true}
              value={firstName}
            />
            <Input
              classes={styles["form-control"]}
              type="text"
              disabled={false}
              placeholder="Last Name"
              onChange={(e) => setLastName(e.target.value)}
              required={true}
              value={lastName}
            />
            <Input
              classes={`${styles["form-control"]} ${styles["textarea"]}`}
              type="textarea"
              disabled={false}
              placeholder="Bio"
              onChange={(e) => setBio(e.target.value)}
              required={true}
              value={bio}
            />
            {/* <Recorder
              className="test"
              record={true}
              title={""}
              audioURL={audioDetails.url}
              showUIAudio
              handleAudioStop={(data) => handleAudioStop(data)}
              handleAudioUpload={(data) => handleAudioUpload(data)}
              handleCountDown={(data) => handleCountDown(data)}
              handleReset={() => handleReset()}
              mimeTypeToUseWhenRecording={`audio/webm`}
            /> */}
            <Button text={"Proceed"} type="submit" />
          </form>
        </main>
      {/* </CSSTransition> */}
    </Fragment>
  );
};

export default GetToKnow;
