import { Fragment } from "react";
import "./App.css";
import MentorRoutes from "./routes/MentorRoutes";
import AdminRoutes from "./routes/AdminRoutes";
import "reactjs-popup/dist/index.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <Fragment>
      <MentorRoutes />
      <AdminRoutes />
    </Fragment>
  );
}

export default App;
