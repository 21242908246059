import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import validator from "validator";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import MetaData from "../../utils/MetaData";
import classes from "./MentorSignUp.module.css";
import styles from "../inputs/Input.module.css";
import Input from "../inputs/Input";
import Button from "../UI/buttons/Button";
import { createUser } from "../../controllers/auth";

const GetToKnow = () => {
  const [formIsValid, setFormIsValid] = useState(false);
  const [usernameIsValid, setUsernameIsValid] = useState(undefined);
  const [checkingUsername, setCheckingUsername] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [pwdShow, setPwdShow] = useState(false);
  const [confirmPwdShow, setConfirmPwdShow] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      validator.isStrongPassword(password, {
        pointsPerUnique: 0,
        pointsPerRepeat: 0,
      }) &&
      password === confirmPassword &&
      password.length > 0
    ) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }

    if (
      !username.length > 0 ||
      !/^[A-Za-z0-9._~()'!*:@,;+?-]*$/g.test(username)
    ) {
      setUsernameIsValid(false);
    }

    if (
      username.length > 0 &&
      /^[A-Za-z0-9._~()'!*:@,;+?-]*$/g.test(username)
    ) {
      let timeout = setTimeout(() => {
        setCheckingUsername(true);
        const post = async () => {
          await axios
            .post("https://oddience.herokuapp.com/api/v1/user/check/username", {
              username,
            })
            .then((response) => {
              setCheckingUsername(false);
              setUsernameIsValid(true);
            })
            .catch((err) => {
              console.log(err);
              setCheckingUsername(false);
              setUsernameIsValid(false);
            });
        };
        post();
      }, 2000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [password, confirmPassword, username]);

  const goBack = () => {
    navigate("/coach/sign-up/almost-done");
  };

  const usernameChangeHandler = async (e) => {
    if (e.target.value.length > 0) {
      setCheckingUsername(true);
    } else {
      setCheckingUsername(false);
    }
    setUsername(e.target.value);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    const details = JSON.parse(window.localStorage.getItem("details"));
    const user = {
      firstName: details.bioData.firstName,
      lastName: details.bioData.lastName,
      bio: details.bioData.bio,
      email: details.email,
      skills: details.skillIds,
      pricePerSesh: details.pricePerSesh,
      profileImageUrl: details.profileImageUrl,
      linkedInId: details.linkedInId,
      username,
      password,
      confirmPassword,
      role: "mentor",
    };

    const data = await createUser(user);
    if (data.error) {
      setLoading(false);
      return toast.error(data.error.message);
    }
    const newSkill = details.skillIds.filter((skill) => {
      return typeof skill === "object";
    });

    if (newSkill.length > 0) {
      toast(
        "Your topic submission has been made. You will be contacted via email regarding it's status as soon as it is reviewed."
      );
      setLoading(false);
      window.localStorage.clear();
      window.sessionStorage.clear();
      window.localStorage.setItem("token", data.token);
      window.localStorage.setItem("username", data.username);
      setTimeout(() => {
        navigate("/coach/sign-up/success");
      }, 5000);
    } else {
      setLoading(false);
      window.localStorage.clear();
      window.sessionStorage.clear();
      window.localStorage.setItem("token", data.token);
      window.localStorage.setItem("username", data.username);
      setTimeout(() => {
        navigate("/coach/sign-up/success");
      }, 500);
    }
  };

  return (
    <Fragment>
      <MetaData title={"Create your unique password."} />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
      />
      <Button
        text={
          <span>
            <i className="fas fa-arrow-left"></i> Back
          </span>
        }
        type="button"
        variant="plain"
        onClick={goBack}
      />
      {/* <CSSTransition classNames={"slide-in"} timeout={500} in={inProp}> */}
      <main className={`${classes["sign-up-main"]} ${classes.wide}`}>
        <h1>Create your unique username and password</h1>
        <form className={classes.form} onSubmit={submitHandler}>
          <Input
            id="username"
            classes={styles["form-control"]}
            type={"text"}
            disabled={false}
            autoComplete={"off"}
            styleExt={{ marginBottom: "0" }}
            placeholder="Username"
            onChange={usernameChangeHandler}
            required={true}
          />
          <label
            style={{
              margin: "0.5rem 0",
              display: "block",
              width: "100%",
              fontSize: "0.6rem",
              color:
                typeof usernameIsValid === "undefined" ||
                username.length === 0 ||
                checkingUsername
                  ? "black"
                  : usernameIsValid
                  ? "green"
                  : "red",
            }}
          >
            {username.length > 0
              ? checkingUsername
                ? "...checking"
                : typeof usernameIsValid === "undefined"
                ? ""
                : usernameIsValid
                ? "username is available"
                : "username is unavailable"
              : ""}
          </label>
          <p
            style={{ fontSize: "0.6rem", width: "100%", marginBottom: "2rem" }}
          >
            (Your username must start with a number or letter and can only
            contain these symbols: ._~()'!*:@,;+?-)
          </p>
          <Input
            id="password"
            classes={styles["form-control"]}
            type={pwdShow ? "text" : "password"}
            disabled={false}
            styleExt={{ marginBottom: "0" }}
            autoComplete="off"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
            required={true}
            children={
              pwdShow ? (
                <i className="far fa-eye"></i>
              ) : (
                <i className="far fa-eye-slash"></i>
              )
            }
            onClick={() => {
              if (pwdShow) {
                setPwdShow(false);
              } else {
                setPwdShow(true);
              }
            }}
          />

          <label
            htmlFor="password"
            style={{
              margin: "0.5rem 0 1rem 0",
              display: "block",
              width: "100%",
              fontSize: "0.6rem",
            }}
          >
            Your password must contain: <br />
            <ul style={{ listStyleType: "none" }}>
              <li>
                a least 8 characters.
                {password.length >= 8 ? (
                  <i className="text-success ms-2 fad fa-check-circle" />
                ) : (
                  <i className="text-danger ms-2 fad fa-times-circle" />
                )}
              </li>
              <li>
                a minimum of 1 lower case letter.
                {/[a-z]/.test(password) ? (
                  <i className="text-success ms-2 fad fa-check-circle" />
                ) : (
                  <i className="text-danger ms-2 fad fa-times-circle" />
                )}
              </li>
              <li>
                a minimum of 1 upper case letter.
                {/[A-Z]/.test(password) ? (
                  <i className="text-success ms-2 fad fa-check-circle" />
                ) : (
                  <i className="text-danger ms-2 fad fa-times-circle" />
                )}
              </li>
              <li>
                a minimum of 1 numeric character.
                {/[0-9]/.test(password) ? (
                  <i className="text-success ms-2 fad fa-check-circle" />
                ) : (
                  <i className="text-danger ms-2 fad fa-times-circle" />
                )}
              </li>
              <li>
                {"a minimum of 1 special character."}
                {/[^A-Za-z0-9]/.test(password) ? (
                  <i className="text-success ms-2 fad fa-check-circle" />
                ) : (
                  <i className="text-danger ms-2 fad fa-times-circle" />
                )}
              </li>
            </ul>
          </label>

          <Input
            classes={styles["form-control"]}
            type={confirmPwdShow ? "text" : "password"}
            disabled={false}
            placeholder="Confirm Password"
            onChange={(e) => setConfirmPassword(e.target.value)}
            required={true}
            children={
              confirmPwdShow ? (
                <i className="far fa-eye"></i>
              ) : (
                <i className="far fa-eye-slash"></i>
              )
            }
            onClick={() => {
              if (confirmPwdShow) {
                setConfirmPwdShow(false);
              } else {
                setConfirmPwdShow(true);
              }
            }}
          />
          <Button
            styles={{
              width: "100%",
              backgroundColor:
                !formIsValid || !usernameIsValid ? "gray" : "#03B1D8",
              cursor: !formIsValid || !usernameIsValid ? "default" : "select",
            }}
            variant="primary"
            type={"submit"}
            text="Proceed"
            loading={loading}
            disabled={!formIsValid || !usernameIsValid}
            divStyles={{ margin: "1rem 0 0 0", width: "50%" }}
          />
        </form>
      </main>
      {/* </CSSTransition> */}
    </Fragment>
  );
};

export default GetToKnow;
