module.exports = [
  {
    label: "Africa",
    options: [
      { value: "Africa/Casablanca", label: "Morocco Standard Time" },
      { value: "Africa/Dakar", label: "Greenwich Standard Time" },
      { value: "Africa/Johannesburg", label: "South Africa Standard Time" },
      { value: "Africa/Lagos", label: "W. Central Africa Standard Time" },
      { value: "Africa/Windhoek", label: "Namibia Standard Time" },
    ],
  },
  {
    label: "US / Canada",
    options: [
      { value: "America/Los_Angeles", label: "Pacific Time" },
      { value: "America/Denver", label: "Mountain Time" },
      { value: "America/Chicago", label: "Centeral Time" },
      { value: "America/New_York", label: "Eastern Time" },
      { value: "America/Anchorage", label: "Alaskan Time" },
      { value: "America/Honolulu", label: "Hawaiian Time" },
      { value: "America/Halifax", label: "Atlantic Time" },
      { value: "America/St_Johns", label: "NewFoundLand Time" },
      { value: "America/Phoenix", label: "Arizona Time - MST" },
    ],
  },
  {
    label: "Europe",
    options: [
      { value: "Europe/London", label: "UK,Ireland,Lisbon" },
      { value: "Europe/Madrid", label: "Central European Time" },
      { value: "Europe/Bucharest", label: "Eastern European Time" },
      { value: "Europe/Minsk", label: "Minsk Time" },
    ],
  },
  {
    label: "Australia",
    options: [
      { value: "Australia/Perth", label: "W. Australia Standard Time" },
      { value: "Australia/Eucla", label: "Australian Central Western Time" },
      { value: "Australia/Darwin", label: "AUS Central Standard Time" },
      { value: "Australia/Adelaide", label: "Australian Adelaide Time" },
      { value: "Australia/Brisbane", label: "Australian Brisbane Time" },
      { value: "Australia/Sydney", label: "Australian Eastern Time" },
      { value: "Australia/Lord_Howe", label: "Australian Lord Howe Time" },
      { value: "Australia/Hobart", label: "Tasmania Standard Time" },
    ],
  },
  {
    label: "Asia",
    options: [
      { value: "Asia/Amman", label: "Jordan Standard Time" },
      { value: "Asia/Nicosia", label: "GTB Standard Time" },
      { value: "Asia/Beirut", label: "Middle East Standard Time" },
      { value: "Asia/Hebron", label: "West Bank Standard Time" },
      { value: "Asia/Hebron", label: "West Bank Standard Time" },
      { value: "Asia/Jerusalem", label: "Israel Standard Time" },
      { value: "Asia/Baghdad", label: "West Bank Standard Time" },
      { value: "Asia/Famagusta", label: "Turkey Standard Time" },
      { value: "Asia/Tehran", label: "Iran Standard Time" },
      { value: "Asia/Dubai", label: "Arabian Standard Time" },
      { value: "Asia/Baku", label: "Azerbaijan Standard Time" },
      { value: "Asia/Tbilisi", label: "Georgian Standard Time" },
      { value: "Asia/Yerevan", label: "Caucasus Standard Time" },
      { value: "Asia/Tbilisi", label: "Afghanistan Standard Time" },
      { value: "Asia/Kabul", label: "West Asia Standard Time" },
      { value: "Asia/Yekaterinburg", label: "Ekaterinburg Standard Time" },
      { value: "Asia/Karachi", label: "Pakistan Standard Time" },
      { value: "Asia/Calcutta", label: "India Standard Time" },
      { value: "Asia/Colombo", label: "Sri Lanka Standard Time" },
      { value: "Asia/Katmandu", label: "Nepal Standard Time" },
      { value: "Asia/Almaty", label: "Central Asia Standard Time" },
      { value: "Asia/Dhaka", label: "Bangladesh Standard Time" },
      { value: "Asia/Omsk", label: "Omsk Standard Time" },
      { value: "Asia/Rangoon", label: "Myanmar Standard Time" },
      { value: "Asia/Bangkok", label: "SE Asia Standard Time" },
      { value: "Asia/Barnaul", label: "Altai Standard Time" },
      { value: "Asia/Krasnoyarsk", label: "W. Mongolia Standard Time" },
      { value: "Asia/Novosibirsk", label: "North Asia Standard Time" },
      { value: "Asia/Omsk", label: "N. Central Asia Standard Time" },
      { value: "Asia/Tomsk", label: "Tomsk Standard Time" },
      { value: "Asia/Shanghai", label: "China Standard Time" },
      { value: "Asia/Irkutsk", label: "North Asia East Standard Time" },
      { value: "Asia/Singapore", label: "Singapore Standard Time" },
    ],
  },
];
