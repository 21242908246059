import React, { useEffect, useState, Fragment } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "../inputs/Input.module.css";
import Input from "../inputs/Input";
import { useParams } from "react-router-dom";
import Select from "react-select";
import Button from "../UI/buttons/Button";

const CreateAppointment = ({
  availableDays,
  availableTimes,
  usingPaystack,
}) => {
  const [days, setDays] = useState([]);
  const [daySelected, setDaySelected] = useState(null);
  const [timeSelected, setTimeSelected] = useState(null);
  const [timeOptions, setTimeOptions] = useState(null);
  const [email, setEmail] = useState("");
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [loading, setLoading] = useState(false);
  const { username } = useParams();

  useEffect(() => {
    if (days.length === 0) {
      const dayTemp = [];
      availableDays.map((day) => {
        if (day.available) {
          dayTemp.push({
            value: day.date,
            label: new Date(day.date).toDateString(),
          });
        }
        setDays(dayTemp);
        return day;
      });
    }

    const time = [];
    if (daySelected) {
      availableTimes.map((availableTime) => {
        if (availableTime.date === daySelected.value) {
          time.push({
            value: availableTime.startDateTime,
            label: availableTime.displayTime,
          });
          setTimeOptions(time);
        }
        return availableTime;
      });
    }
  }, [daySelected, availableDays, availableTimes, days.length]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await axios
      .post(
        `https://oddience.herokuapp.com/api/v1/mentor/appointment/create/${username}${
          usingPaystack && `?paystack=true`
        }`,
        {
          fName,
          lName,
          email,
          startDateTime: new Date(timeSelected.value).getTime(),
        }
      )
      .then((response) => {
        setLoading(false);
        return window.location.replace(response.data.url);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.data.message === "BOOKING-138") {
          return toast.error(
            "The time selected is no longer available. Please select another time slot."
          );
        }
        return toast.error(error.response.data.message);
      });
  };

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
      />
      <p style={{ marginBottom: "2rem" }}>
        You can only book as far ahead as a week from now.
      </p>
      <form onSubmit={handleFormSubmit}>
        <label htmlFor="appointment-day">Appointment Date:</label>
        <Select
          id="appointment-day"
          //   defaultValue={stageSelected}
          onChange={setDaySelected}
          options={days}
          isMulti={false}
          className={styles["form-select"]}
          isSearchable={true}
          value={daySelected}
        />
        {daySelected && (
          <Fragment>
            <label htmlFor="appointment-time">Available Times:</label>
            <Select
              id="appointment-time"
              //   defaultValue={stageSelected}
              onChange={setTimeSelected}
              options={timeOptions}
              isMulti={false}
              className={styles["form-select"]}
              isSearchable={true}
              value={timeSelected}
            />
            <label htmlFor="fName">First name:</label>
            <Input
              id="fName"
              classes={styles["form-control"]}
              type="text"
              disabled={false}
              placeholder="First name"
              onChange={(e) => setFName(e.target.value)}
              required={true}
              value={fName}
            />
            <label htmlFor="lName">Last name:</label>
            <Input
              id="lName"
              classes={styles["form-control"]}
              type="text"
              disabled={false}
              placeholder="Last name"
              onChange={(e) => setLName(e.target.value)}
              required={true}
              value={lName}
            />
            <label htmlFor="email">Email</label>
            <Input
              id="email"
              classes={styles["form-control"]}
              type="email"
              disabled={false}
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              required={true}
              value={email}
            />
          </Fragment>
        )}
        <Button loading={loading} type={"submit"} text="Book appointment" />
      </form>
    </div>
  );
};

export default CreateAppointment;
