import React from "react";
import Button from "../UI/buttons/Button";

const AccBalance = ({ accountDetails, classes, ...rest }) => {
  // const [balance, setBalance] = useState(null);
  // useEffect(() => {
  //   if (accountDetails.accBalance) {
  //     const accountBalance = accountDetails.accBalance.toFixed(2);
  //     setBalance(formatCurrency(accountBalance));
  //   }
  // }, [accountDetails.accBalance]);
  return (
    <div className={classes.bio}>
      <h3
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "1rem",
        }}
        className={classes["block-heading"]}
      >
        Accounts{" "}
      </h3>
      <div>
        <div
          style={{
            width: "100%",
            maxWidth: "20rem",
            borderRadius: "1rem",
            backgroundColor: "rgb(3 177 216 / 9%)",
            padding: "2rem",
            display: "flex",
            alignItems: "center",
            // justifyContent: "space-between",
          }}
        >
          <div>
            <i className="fal fa-university" style={{ fontSize: "3rem" }} />
          </div>
          <div style={{ marginLeft: "2rem" }}>
            <div
              style={{
                fontSize: "0.8rem",
                color: "#21274f",
              }}
            >
              {accountDetails.bank.name}
            </div>
            <div
              style={{
                fontSize: "1.5rem",
                color: "#21274f",
                letterSpacing: "3px",
              }}
            >
              {accountDetails.subaccount_details.accNo}
            </div>
          </div>

          {/* {rest.lastWithdrawalPending && (
          <p
            style={{
              color: "red",
              opacity: "0.7",
              fontSize: "0.65rem",
              marginTop: "1rem",
            }}
          >
            Your last attempt at withdrawal is pending and you cannot make any
            withdrawals at the moment
          </p>
        )}
        {rest.lastWithdrawalFailed && (
          <p
            style={{
              color: "red",
              opacity: "0.7",
              fontSize: "0.65rem",
              marginTop: "1rem",
            }}
          >
            Your last attempt at withdrawal failed. Kindly reattempt withdrawal
          </p>
        )} */}
        </div>
        <Button
          text="Change Payout Account"
          variant="primary"
          styles={{
            width: "100%",
            maxWidth: "20rem",
          }}
          divStyles={{
            marginTop: "2rem",
            width: "100%",
          }}
          onClick={rest.changeAccountDetails}
        />
      </div>
    </div>
  );
};

export default AccBalance;
