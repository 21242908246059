import React from "react";

const Filters = ({ onChange, ...rest }) => {
  return (
    <div {...rest}>
      <span>
        <input
          type="radio"
          onChange={(e) => onChange(e.target.value)}
          style={{ marginRight: "1rem" }}
          id="all"
          name="filter"
          value="all"
        />
        <label htmlFor="all">All</label>
      </span>
      <span>
        <input
          type="radio"
          onChange={(e) => onChange(e.target.value)}
          style={{ marginRight: "1rem" }}
          id="review"
          name="filter"
          value="review"
        />
        <label htmlFor="review">Awaiting Review</label>
      </span>
      <span>
        <input
          type="radio"
          onChange={(e) => onChange(e.target.value)}
          style={{ marginRight: "1rem" }}
          id="rejected"
          name="filter"
          value="rejected"
        />
        <label htmlFor="rejected">Rejected</label>
      </span>
      <span>
        <input
          type="radio"
          onChange={(e) => onChange(e.target.value)}
          style={{ marginRight: "1rem" }}
          id="active"
          name="filter"
          value="active"
        />
        <label htmlFor="active">Active</label>
      </span>
    </div>
  );
};

export default Filters;
