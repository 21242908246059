import React, { useState } from "react";
import axios from "axios";
import CurrencyInput from "react-currency-input-field";
import Button from "../UI/buttons/Button";
import styles from "../inputs/Input.module.css";

const UpdatePrice = ({ prevPrice, close }) => {
  const [price, setPrice] = useState(prevPrice ? prevPrice : "");
  const [loading, setLoading] = useState(false);

  const handlePriceBlur = (e) => {
    let value = e.target.value;
    if (value.length > 4) {
      value = value.split(",").join("");
    }
    if (value.indexOf(".") === -1) {
      setPrice(value.slice(1) + ".00");
    } else if (
      value.indexOf(".") !== value.length - 1 &&
      value.slice(value.indexOf(".") + 1).length !== 2
    ) {
      setPrice(value.slice(1) + "0");
    } else if (value[value.length - 1] === ".") {
      setPrice(value.slice(1) + "00");
    }
  };

  const handleSetPrice = (value, name) => {
    if (!value) {
      setPrice(0);
    } else if (value[0] === "0" && !value.includes(".")) {
      setPrice(Number(String(value).slice(1)));
    } else {
      setPrice(value);
    }
  };

  const handleFormSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    await axios
      .put(
        `https://oddience.herokuapp.com/api/v1/mentor/profile/update?price=${price}`,
        {},
        {
          headers: {
            token: window.localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        setLoading(false);
        close();
        // console.log(response);
        window.location.reload();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <CurrencyInput
        intlConfig={{ locale: "en-US", currency: "USD" }}
        id="price-per-sesh"
        placeholder="$10.99"
        defaultValue={10.99}
        value={price}
        // decimalsLimit={2}
        decimalsScale={2}
        //   allowNegativeValue={false}
        //   fixedDecimalLength={2}
        onValueChange={handleSetPrice}
        onBlur={handlePriceBlur}
        className={styles["form-control"]}
      />
      <Button loading={loading} text={"Update"} type="submit" />
    </form>
  );
};

export default UpdatePrice;
