import React, { Fragment } from "react";
import classes from "../signUp/MentorSignUp.module.css";
import Button from "../UI/buttons/Button";
import { toast, ToastContainer } from "react-toastify";

const ShareProfile = ({ username }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(
      `I’ve just joined the Startup Coach Collective! Book a call with me to get advice on growing your startup.\n\nhttps://app.oddience.co/coach/${username}`
    );
    toast("Link Copied to clipboard");
  };

  return (
    <Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
      />

      <main
        style={{ marginTop: "0" }}
        className={`${classes["sign-up-main"]} ${classes.wide} ${classes.home}`}
      >
        {/* <h1>Here’s Your Profile Link!</h1> */}
        {/* <p style={{ padding: "0 2rem", textAlign: "center" }}>
          Share it everywhere to start mentoring!
        </p> */}
        <div style={{ width: "60%", marginTop: "0" }}>
          <div style={{ display: "flex", marginBottom: "0" }}>
            {/* <Button
              // styles={{ marginBottom: "2rem" }}
              styles={{ width: "100%" }}
              divStyles={{margin: '0 0.2rem 0 0', display: "flex", justifyContent: "center" }}
              loading={false}
              text={
                <a
                  href={`http://www.linkedin.com/shareArticle?mini=true&url=https://app.oddience.co/mentor?user=${window.sessionStorage.getItem(
                    "params"
                  )}&title=I’ve just joined the Startup Coach Collective! Book a call with me to get advice on growing your startup.source=oddience.co`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "inherit" }}
                >
                  <i
                    style={{ fontSize: "2rem" }}
                    className="fab fa-linkedin"
                  ></i>
                </a>
              }
              variant="plain"
              type="button"
              onClick={copyToClipboard}
            /> */}

            <Button
              styles={{ width: "100%" }}
              divStyles={{
                margin: "0 0.2rem 0 0",
                display: "flex",
                justifyContent: "center",
              }}
              loading={false}
              text={
                <a
                  href={`http://twitter.com/share?text=I’ve just joined the Startup Coach Collective! Book a call with me to get advice on growing your startup.&url=https://app.oddience.co/coach/${username}`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "inherit" }}
                >
                  <i
                    style={{ fontSize: "2rem" }}
                    className="fab fa-twitter-square"
                  />
                </a>
              }
              variant="plain"
              type="button"
            />

            <Button
              // styles={{ marginBottom: "2rem" }}
              styles={{ width: "100%" }}
              divStyles={{
                margin: "0",
                display: "flex",
                justifyContent: "center",
              }}
              loading={false}
              text={
                <i style={{ fontSize: "2rem" }} className="far fa-copy"></i>
              }
              variant="plain"
              type="button"
              onClick={copyToClipboard}
            />
            <Button
              styles={{ width: "100%" }}
              divStyles={{
                margin: "0 0.2rem 0 0",
                display: "flex",
                justifyContent: "center",
              }}
              loading={false}
              text={
                <a href={`https://app.oddience.co/coach/${username}`} target='_blank' rel="noreferrer" style={{ color: "inherit" }}>
                  <i
                    style={{ fontSize: "1.9rem" }}
                    className="fas fa-external-link-alt"
                  ></i>
                </a>
              }
              variant="plain"
              type="button"
            />
          </div>
        </div>
      </main>
    </Fragment>
  );
};

export default ShareProfile;
