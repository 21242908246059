import React from "react";
import { useNavigate } from "react-router-dom";
import classes from "../dashboards/MentorDashboard.module.css";
import Button from "../UI/buttons/Button";

const AdminDashboard = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{ height: "calc(100vh - 15rem)", marginTop: "1rem" }}
      className={classes["container-main"]}
    >
      <Button
        type="button"
        text="Verify new skills"
        onClick={() => navigate("/admin/skills")}
        variant="plain"
        styles={{ width: "fit-content" }}
        divStyles={{ textAlign: "center", margin: "0" }}
      />
    </div>
  );
};

export default AdminDashboard;
