import React, {
  Fragment,
  useReducer,
  useState,
} from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "../../inputs/Input.module.css";
import Select from "react-select";
import Button from "../../UI/buttons/Button";
import ShowOldAvailability from "../../modals/ShowOldAvailability";
import timeOptions from "../../../utils/timeOptions";
import { availOptions, availReducer } from "../../../utils/availReducer";
import timeZoneOptions from "../../../utils/timeZoneOptions";

// const timeZoneOptions = []
const SetCalendar = ({ close, prevAvailability }) => {
  const [state, dispatch] = useReducer(availReducer, availOptions);
  const [loading, setLoading] = useState(false);
  const [editingZone, setEditingZone] = useState(false);
  const [timezone, setTimezone] = useState(null);
  const [editing, setEditing] = useState(false);
  const [disabled, setDisabled] = useState({
    mon: true,
    tue: true,
    wed: true,
    thu: true,
    fri: true,
    sat: true,
    sun: true,
  });
  const days = [
    { value: "sun", label: "Sunday" },
    { value: "mon", label: "Monday" },
    { value: "tue", label: "Tuesday" },
    { value: "wed", label: "Wednesday" },
    { value: "thu", label: "Thursday" },
    { value: "fri", label: "Friday" },
    { value: "sat", label: "Saturday" },
  ];

  // useEffect(() => {
  //   const load = async () => {
  //     const prevDisabled = disabled;
  //     if (prevAvailability) {
  //       // await Object.keys(prevAvailability).forEach((day) => {
  //       //   prevDisabled[day] = !(
  //       //     prevAvailability[day].startTime !== prevAvailability[day].endTime
  //       //   );
  //       // });

  //       // setDisabled(prevDisabled);
  //       setTimeout(() => {
  //         setLoaded(true);
  //       }, 1000);
  //     }
  //   };

  //   load();
  // }, [disabled]);
  const handleAvailabilityChange = (e) => {
    const prevDisabled = disabled;
    prevDisabled[e.target.value] = !e.target.checked;
    setDisabled({ ...prevDisabled });
  };

  const handleStartTimeChange = (value, element) => {
    if (Number(state[element.name].endTime)) {
      dispatch({
        type: element.name,
        payload: {
          avail: true,
          startTime: Number(value.value),
          endTime: state[element.name].endTime,
        },
      });
    } else {
      dispatch({
        type: element.name,
        payload: { avail: true, startTime: Number(value.value), endTime: 0 },
      });
    }
  };

  function handleEndTimeChange(value, element) {
    if (Number(state[element.name].startTime)) {
      dispatch({
        type: element.name,
        payload: {
          avail: true,
          endTime: Number(value.value),
          startTime: state[element.name].startTime,
        },
      });
    } else {
      dispatch({
        type: element.name,
        payload: { avail: true, endTime: Number(value.value), startTime: 0 },
      });
    }
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const availability = state;

    delete availability.mon.avail;
    delete availability.tue.avail;
    delete availability.wed.avail;
    delete availability.thu.avail;
    delete availability.fri.avail;
    delete availability.sat.avail;
    delete availability.sun.avail;


    if (!timezone && !prevAvailability) {
      setLoading(false);
      return toast.error("Please enter your timezone!!!");
    }
    let timezoneId;

    if (timezone) {
      timezoneId = timezone.value;
    }
    await axios
      .post(
        `https://oddience.herokuapp.com/api/v1/mentor/availability/`,
        {
          availability,
          timezoneId,
        },
        {
          headers: {
            token: window.localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        setLoading(false);
        close(true);
        window.location.reload();
      })
      .catch((err) => {
        setLoading(false);
        return toast.error(err.response.data.message);
        // console.log(err.response.data.message);
      });
  };

  return (
    <Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
      />
      {true ? (
        prevAvailability ? (
          !editing ? (
            <ShowOldAvailability
              prevAvailability={prevAvailability}
              editing={() => {
                setEditing(true);
              }}
              timeOptions={timeOptions}
            />
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "90%" }}>
                <p>What are your availability times?</p>
                <form onSubmit={handleFormSubmit}>
                  <div>
                    {timeOptions &&
                      days.map((day, i) => {
                        return (
                          <Fragment key={i}>
                            <div style={{ marginBottom: "1rem" }}>
                              <input
                                id={day.value}
                                type="checkbox"
                                value={day.value}
                                style={{ marginRight: "0.7rem" }}
                                onChange={handleAvailabilityChange}
                                // checked={false}
                              />
                              <label htmlFor={day.value}>{day.label}</label>
                            </div>
                            <div
                              style={{ display: "flex", marginBottom: "1rem" }}
                            >
                              <Select
                                onChange={handleStartTimeChange}
                                options={timeOptions}
                                isMulti={false}
                                className={styles["form-select-avail"]}
                                blurInputOnSelect={true}
                                placeholder="from"
                                isDisabled={disabled[day.value]}
                                name={day.value}
                              />
                              <Select
                                onChange={handleEndTimeChange}
                                options={timeOptions}
                                isMulti={false}
                                blurInputOnSelect={true}
                                className={styles["form-select-avail"]}
                                placeholder="to"
                                isDisabled={disabled[day.value]}
                                name={day.value}
                              />
                            </div>
                          </Fragment>
                        );
                      })}

                    {editingZone ? (
                      <Fragment>
                        <p style={{ marginTop: "2rem" }}>
                          What timezone are you in?
                        </p>
                        <Select
                          defaultValue={timezone}
                          onChange={setTimezone}
                          options={timeZoneOptions}
                          isMulti={false}
                          className={styles["form-select"]}
                          isSearchable={true}
                          placeholder="Please select your timezone"
                          value={timezone}
                          styles={{ marginBottom: "0" }}
                        />
                        <Button
                          onClick={() => setEditingZone(false)}
                          type={"button"}
                          text="Cancel"
                          variant={"plain"}
                          styles={{ width: "20%" }}
                          divStyles={{ margin: "0 0 2rem 0" }}
                        />
                      </Fragment>
                    ) : (
                      <Button
                        onClick={() => setEditingZone(true)}
                        type={"button"}
                        text="Set a new timezone"
                        variant={"plain"}
                        styles={{ width: "100%" }}
                        divStyles={{ margin: "0 0 3rem 0" }}
                      />
                    )}
                    <Button loading={loading} type={"submit"} text="Submit" />
                  </div>
                </form>
              </div>
            </div>
          )
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ width: "90%" }}>
              <p>What are your availability times?</p>
              <form onSubmit={handleFormSubmit}>
                <div>
                  {timeOptions &&
                    days.map((day, i) => {
                      return (
                        <Fragment key={i}>
                          <div style={{ marginBottom: "1rem" }}>
                            <input
                              id={day.value}
                              type="checkbox"
                              value={day.value}
                              style={{ marginRight: "0.7rem" }}
                              onChange={handleAvailabilityChange}
                              // checked={false}
                            />
                            <label htmlFor={day.value}>{day.label}</label>
                          </div>
                          <div
                            style={{ display: "flex", marginBottom: "1rem" }}
                          >
                            <Select
                              onChange={handleStartTimeChange}
                              options={timeOptions}
                              isMulti={false}
                              className={styles["form-select-avail"]}
                              blurInputOnSelect={true}
                              placeholder="from"
                              isDisabled={disabled[day.value]}
                              name={day.value}
                            />
                            <Select
                              onChange={handleEndTimeChange}
                              options={timeOptions}
                              isMulti={false}
                              blurInputOnSelect={true}
                              className={styles["form-select-avail"]}
                              placeholder="to"
                              isDisabled={disabled[day.value]}
                              name={day.value}
                            />
                          </div>
                        </Fragment>
                      );
                    })}

                  <p style={{ marginTop: "2rem" }}>What timezone are you in?</p>
                  <Select
                    defaultValue={timezone}
                    onChange={setTimezone}
                    options={timeZoneOptions}
                    isMulti={false}
                    className={styles["form-select"]}
                    isSearchable={true}
                    placeholder="Please select your timezone"
                    value={timezone}
                  />
                  {/* {editingZone ? (
                    <Fragment>
                      <p style={{ marginTop: "2rem" }}>
                        What timezone are you in?
                      </p>
                      <Select
                        defaultValue={timezone}
                        onChange={setTimezone}
                        options={timeZoneOptions}
                        isMulti={false}
                        className={styles["form-select"]}
                        isSearchable={true}
                        placeholder="Please select your timezone"
                        value={timezone}
                      />
                    </Fragment>
                  ) : (
                    <Button
                      loading={editingZone}
                      onClick={() => setEditingZone(true)}
                      type={"button"}
                      text="Set a new timezone"
                      variant={"plain"}
                      styles={{ width: "100%" }}
                      divStyles={{ margin: "0 0 3rem 0" }}
                    />
                  )} */}
                  <Button loading={loading} type={"submit"} text="Submit" />
                </div>
              </form>
            </div>
          </div>
        )
      ) : (
        <p></p>
      )}
    </Fragment>
  );
};

export default SetCalendar;
