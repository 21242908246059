import React, { Fragment } from "react";
import classes from "./MentorSignUp.module.css";
import MetaData from "../../utils/MetaData";
import Button from "../UI/buttons/Button";
import { toast, ToastContainer } from "react-toastify";

const GetToKnow = () => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(
      `https://app.oddience.co/coach/${window.localStorage.getItem(
        "username"
      )}`
    );
    toast("Link Copied to clipboard");
  };

  return (
    <Fragment>
      <MetaData title={"All done!"} />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
      />
      {/* <CSSTransition classNames={"slide-in"} timeout={500} in={inProp}> */}
      <main
        className={`${classes["sign-up-main"]} ${classes.wide} ${classes.home}`}
      >
        <h1>Here’s Your Profile Link!</h1>
        <p style={{ padding: "0 2rem", textAlign: "center" }}>
          Share it everywhere to start mentoring!
        </p>
        <div style={{ width: "60%", marginTop: "8rem" }}>
          <div style={{ display: "flex", marginBottom: "3rem" }}>
            {/* <Button
                // styles={{ marginBottom: "2rem" }}
                styles={{ width: "100%" }}
                divStyles={{ display: "flex", justifyContent: "center" }}
                loading={false}
                text={
                  <a
                    href={`http://www.linkedin.com/shareArticle?mini=true&url=https://app.oddience.co/coach?user=${window.localStorage.getItem(
                      "params"
                    )}&title=I’ve just joined the Startup Coach Collective! Book a call with me to get advice on growing your startup.source=oddience.co`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "inherit" }}
                  >
                    <i
                      style={{ fontSize: "2rem" }}
                      className="fab fa-linkedin"
                    ></i>
                  </a>
                }
                variant="plain"
                type="button"
                onClick={copyToClipboard}
              /> */}

            <Button
              styles={{ width: "100%" }}
              divStyles={{ display: "flex", justifyContent: "center" }}
              loading={false}
              text={
                <a
                  href={`http://twitter.com/share?text=I’ve just joined the Startup Coach Collective! Book a call with me to get advice on growing your startup.&url=https://app.oddience.co/coach/${window.localStorage.getItem(
                    "username"
                  )}`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "inherit" }}
                >
                  <i
                    style={{ fontSize: "2rem" }}
                    className="fab fa-twitter-square"
                  />
                </a>
              }
              variant="plain"
              type="button"
            />

            <Button
              // styles={{ marginBottom: "2rem" }}
              styles={{ width: "100%" }}
              divStyles={{ display: "flex", justifyContent: "center" }}
              loading={false}
              text={
                <i style={{ fontSize: "2rem" }} className="far fa-copy"></i>
              }
              variant="plain"
              type="button"
              onClick={copyToClipboard}
            />
            <Button
              styles={{ width: "100%" }}
              divStyles={{
                margin: "5rem 0 0 0",
                display: "flex",
                justifyContent: "center",
              }}
              loading={false}
              text={
                <a
                  href={`https://app.oddience.co/coach/${window.localStorage.getItem(
                    "username"
                  )}`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "inherit" }}
                >
                  <i
                    style={{ fontSize: "1.9rem" }}
                    className="fas fa-external-link-alt"
                  ></i>
                </a>
              }
              variant="plain"
              type="button"
            />
          </div>
          <a
            href={`https://app.oddience.co/coach/dashboard`}
            target="_blank"
            rel="noreferrer"
          >
            <Button
              loading={false}
              text={"See Your Dashboard"}
              type="button"
              // variant="success"
              // onClick={goToDashboard}
            />
          </a>
        </div>
      </main>
      {/* </CSSTransition> */}
    </Fragment>
  );
};

export default GetToKnow;
