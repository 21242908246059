import React from "react";
import classes from "./Footer.module.css";
const Footer = () => {
  return (
    <div className={classes["footer-main"]}>
      <span>Powered by Oddience</span>
      {/* <span>2022 &#169; Oddience.co</span> */}
      {/* <span>All rights reserved</span> */}
    </div>
  );
};

export default Footer;
