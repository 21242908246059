import React, { Fragment, useEffect } from 'react'
import { Modal } from 'react-bootstrap'

const BasicModal = ({title, closeButton, centered, show, close, body, footer}) => {
    useEffect(() => {
        return
    }, [show])
  return (
    <Fragment>
        <Modal centered={centered} show={show} onHide={close}>
            <Modal.Header closeButton={closeButton}>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>{footer}</Modal.Footer>
        </Modal>
    </Fragment>
  )
}

export default BasicModal