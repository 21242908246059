import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "../UI/buttons/Button";
import classes from "./MentorDashboard.module.css";
import { useSearchParams, useParams } from "react-router-dom";
import BasicModal from "../UI/modals/BasicModal";
import CreateAppointment from "../modals/CreateAppointment";
import MetaData from "../../utils/MetaData";
import PaymentSuccessful from "../modals/PaymentSuccessful";

const MentorDashboard = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [successShow, setSuccessShow] = useState(false);
  const [failedTransaction, setFailedTransaction] = useState(false);
  let [searchParams] = useSearchParams();
  const [mentor, setMentor] = useState(null);
  const { username } = useParams();

  useEffect(() => {
    async function loadPage() {
      try {
        let token;
        let transaction_id;
        let tx_ref;
        let transaction_status;
        if (searchParams.get("token") !== "failed transaction") {
          token = searchParams.get("token");
          transaction_id = searchParams.get("transaction_id");
          tx_ref = searchParams.get("trxref");
          transaction_status = searchParams.get("status");
        } else {
          token = null;
        }
        const response = await axios.get(
          `https://oddience.herokuapp.com/api/v1/mentor/${username}?tzOffset=${new Date(
            Date.now()
          ).getTimezoneOffset()}&date=${new Date(Date.now())}&${
            token ? "token=" : "" + token
          }${tx_ref ? `&token=${tx_ref}&paystack=true` : ""}${
            transaction_id ? `&transaction_id=${transaction_id}` : ""
          }${
            transaction_status
              ? `&transaction_status=${transaction_status} `
              : ""
          }`
        );

        if (
          searchParams.get("token") === "failed transaction" ||
          searchParams.get("status") === "cancelled"
        ) {
          setFailedTransaction(true);
        }

        setMentor(response.data.mentor);

        setPageLoading(false);

        if (response.data.mentor.transaction && searchParams.get("token")) {
          if (response.data.mentor.transaction.status === "paid") {
            setSuccessShow(true);
          }
          if (response.data.mentor.transaction.status === "failed") {
            setFailedTransaction(true);
          }
        }
      } catch (err) {
        return toast.error("An error occurred. Please refresh the page");
      }
    }
    loadPage();
  }, [searchParams, username]);

  const handleModalClose = () => {
    setModalShow(false);
  };

  const handleTransactionClose = () => {
    setFailedTransaction(false);
    setSuccessShow(false);
    window.location.replace(`https://app.oddience.co/coach/${username}`);
  };
  return (
    <Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
      />
      {pageLoading ? (
        <div className={classes["page-loading"]}>
          <img
            src={`${process.env.PUBLIC_URL}/static/images/header-logo.png`}
            alt="Page loading indicator"
          ></img>
        </div>
      ) : mentor ? (
        <div className={classes["container-main"]}>
          <MetaData title={`${mentor.name}`} />
          <div className={classes["profile-img-container"]}>
            {mentor.imageUrl ? (
              <img
                className={classes["profile-image"]}
                src={mentor.imageUrl}
                alt={`${mentor.name} profile`}
              />
            ) : (
              <i className="fal fa-user"></i>
            )}
          </div>
          <h1
            style={{ marginTop: "2rem" }}
            className={classes["dashboard-heading"]}
          >
            {mentor.name}
          </h1>
          <div className={classes.block}>
            <span className={classes["big-text"]}>
              &#36;
              {String(mentor.pricePerSesh).indexOf(".") < 0
                ? String(mentor.pricePerSesh) + ".00"
                : String(mentor.pricePerSesh)}
            </span>
            <span className={classes["small-text"]}>Price per session</span>
          </div>
          <div className={classes.block}>
            <span className={classes["big-text"]}>30 mins</span>
            <span className={classes["small-text"]}>Session length</span>
          </div>
          <div className={classes.block}>
            <span className={classes["big-text"]}>{mentor.bookings}</span>
            <span className={classes["small-text"]}>
              Number of sessions held
            </span>
          </div>
          {mentor.account_id && mentor.account_complete ? (
            <Button
              text="Book a session"
              type="button"
              styles={{ width: "50%", marginTop: "3rem" }}
              onClick={() => setModalShow(true)}
            />
          ) : mentor.usingPaystack ? (
            <Button
              text="Book a session"
              type="button"
              styles={{ width: "50%", marginTop: "3rem" }}
              onClick={() => setModalShow(true)}
            />
          ) : (
            <Button
              text="Book a session"
              type="button"
              styles={{
                width: "50%",
                marginTop: "3rem",
                backgroundColor: "rgb(138, 138, 138)",
                cursor: "default",
              }}
              // onClick={() => setModalShow(true)}
              loading={false}
            />
          )}

          <div className={classes.bio}>
            <h3 className={classes["block-heading"]}>Bio</h3>
            <p>{mentor.bio}</p>
          </div>
          <div className={classes.bio}>
            <h3 className={classes["block-heading"]}>Expertise</h3>
            <p>
              {mentor.skills.map((skill, i) => {
                return (
                  <Fragment key={i}>
                    <span>{skill.label}</span>
                    {i < mentor.skills.length - 1 && <br />}
                  </Fragment>
                );
              })}
            </p>
          </div>
          <div className={classes.bio}>
            <h3 className={classes["block-heading"]}>Best Fit For</h3>
            <p>
              {mentor.stage
                ? mentor.stage.label
                : "Early Stage, Growth Stage and Scale Up"}
            </p>
          </div>
          <BasicModal
            show={modalShow}
            title={`Book Appointment with ${mentor.name}`}
            closeButton={true}
            close={handleModalClose}
            body={
              <CreateAppointment
                availableDays={mentor.availableDays}
                availableTimes={mentor.availableTimes}
                usingPaystack={mentor.usingPaystack}
              />
            }
          />
          <BasicModal
            show={successShow}
            title={`Congratulations!`}
            centered={true}
            closeButton={true}
            close={handleTransactionClose}
            body={<PaymentSuccessful />}
          />
        </div>
      ) : (
        <div style={{ height: "78vh" }}></div>
      )}
      <BasicModal
        show={failedTransaction}
        title={`Transaction failed`}
        closeButton={true}
        close={handleTransactionClose}
        centered={true}
        body={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <i
              style={{ fontSize: "10rem", color: "red", marginTop: "1rem" }}
              className="fas fa-times-circle"
            ></i>
            <p style={{ textAlign: "center", marginTop: "2rem" }}>
              Seems there was an issue with the last transaction. Do try again.{" "}
              <br></br>If issue persists please contact us at{" "}
              <a style={{ color: "black" }} href="mailto:hello@oddience.co">
                oddience
              </a>
            </p>
          </div>
        }
      />
    </Fragment>
  );
};

export default MentorDashboard;
