import React, { useState, Fragment, useEffect } from "react";
import axios from "axios";
import Button from "../UI/buttons/Button";
import classes from "./SelectPaymentMethod.module.css";
import Input from "../inputs/Input";
import Select from "react-select";
import styles from "../inputs/Input.module.css";

// const countries = [
//   { value: "NG", label: "Nigeria" },
//   { value: "GH", label: "Ghana" },
//   { value: "KE", label: "Kenya" },
//   { value: "ZA", label: "South Africa" },
//   { value: "TZ", label: "Tanzania" },
// ];

const SelectPaymentMethod = ({ getPaidStripe, usingPaystack }) => {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [countryLoading, setCountryLoading] = useState(false);
  const [country, setCountry] = useState("");
  const [banks, setBanks] = useState(null);
  const [bankOptions, setBankOptions] = useState(null);
  const [bank, setBank] = useState(null);
  const [selectedBank, setSelectedBank] = useState("");
  const [countries, setCountries] = useState([]);
  const [accNo, setAccNo] = useState("");
  const [countrySelected, setCountrySelected] = useState(false);
  const [paystack, setPaystack] = useState(false);

  const stripeClickHandler = async () => {
    setLoading(true);
    await getPaidStripe();
  };

  const getPaidPaystack = async (e) => {
    setPaystack(true);
  };

  const countryChangeHandler = async (e) => {
    setCountry(e);
    setBankOptions(null);
    setSelectedBank(null);
    setLoading(true);
    setCountrySelected(true);

    await axios
      .get(`https://oddience.herokuapp.com/api/v1/paystack/banks?country=${e.value}`)
      .then((response) => {
        const { banks } = response.data;
        const bankOptions = banks
          .sort((a, b) => {
            let fa = a.name.toLowerCase(),
              fb = b.name.toLowerCase();

            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          })
          .map((bank) => {
            return { value: bank.code, label: bank.name };
          });
        setBankOptions(bankOptions);
        setBanks(banks);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const bankChangeHandler = async (e) => {
    setBank(
      banks.filter((bank) => {
        return bank.code === e.value;
      })[0]
    );
    setSelectedBank(e);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    await axios
      .post(
        `https://oddience.herokuapp.com/api/v1/paystack/bank/new`,
        {
          country: country.value,
          bank,
          accNo,
        },
        {
          headers: {
            token: window.localStorage.getItem("token"),
          },
        }
      )
      .then(() => {
        setSubmitting(false);
        window.location.reload();
      })
      .catch((error) => {
        setSubmitting(false);
        console.log(error);
      });
  };

  const getCountries = async () => {
    setCountryLoading(true);
    await axios
      .get("https://oddience.herokuapp.com/api/v1/paystack/countries")
      .then((res) => {
        const formatCountry = res.data.countries.map((c) => {
          return {
            label: c.name,
            value: c.name.toLowerCase(),
          };
        });
        setCountries(formatCountry);
        setCountryLoading(false);
      });
  };

  useEffect(() => {
    getCountries();
  }, []);

  return paystack || usingPaystack ? (
    <form onSubmit={submitHandler}>
      <p>Please provide your bank details</p>
      <div>
        <label htmlFor="country">Please select your country.</label>
        <Select
          defaultValue={country}
          onChange={countryChangeHandler}
          options={countries}
          isMulti={false}
          isLoading={countryLoading}
          className={styles["form-select"]}
          isSearchable={true}
          value={country}
        />
      </div>
      {countrySelected && (
        <Fragment>
          <label htmlFor="bank">Bank name:</label>
          <Select
            id="bank"
            defaultValue={selectedBank}
            onChange={bankChangeHandler}
            options={bankOptions}
            isMulti={false}
            isLoading={loading}
            className={styles["form-select"]}
            required={true}
            isSearchable={true}
            value={selectedBank}
          />
          <Input
            type="number"
            onChange={(e) => setAccNo(e.target.value)}
            classes={`${styles["form-control"]} ${classes["acc-number"]}`}
            required={true}
            value={accNo}
            placeholder="Bank Account Number"
          />
          <Button
            text={"Save Account Details"}
            type="submit"
            loading={submitting}
          />
        </Fragment>
      )}
    </form>
  ) : (
    <div>
      <p style={{ marginBottom: "3rem" }}>
        How do you want to recieve payments? Click on your preffered payment
        method below to get started.
      </p>
      <div className={classes["payment-container"]}>
        <Button
          styles={{
            width: "100%",
            textAlign: "center",
            height: "fit-content",
            cursor: loading ? "default" : "pointer",
          }}
          variant="plain"
          type={"button"}
          text={
            <img
              src={`${process.env.PUBLIC_URL}/static/images/paystack.png`}
              alt="paystack payment method logo"
              style={{
                width: "60%",
              }}
              className={`${classes["payment-portal-logo"]} ${
                loading ? classes["loading"] : ""
              }`}
            />
          }
          divStyles={{ margin: "0", width: "100%" }}
          onClick={getPaidPaystack}
          disabled={loading}
        />

        <p style={{ fontSize: "0.75rem" }}>For mentors in Africa</p>
      </div>
      <div className={classes["payment-container"]}>
        <Button
          styles={{
            width: "100%",
            textAlign: "center",
            height: "fit-content",
            cursor: loading ? "default" : "pointer",
          }}
          variant="plain"
          type={"button"}
          text={
            <img
              src={`${process.env.PUBLIC_URL}/static/images/stripe-logo.png`}
              alt="stripe payment method logo"
              style={{
                width: "50%",
              }}
              className={`${classes["payment-portal-logo"]} ${
                loading ? classes["loading"] : ""
              }`}
            />
          }
          divStyles={{ margin: "0", width: "100%" }}
          onClick={stripeClickHandler}
          disabled={loading}
        />

        <p style={{ fontSize: "0.75rem", marginTop: "1.3rem" }}>
          For mentors outside Africa
        </p>
      </div>
    </div>
  );
};

export default SelectPaymentMethod;
