import React, { Fragment, useState, useEffect, useRef } from "react";
import axios from "axios";
import sha256 from "crypto-js/sha256";
import Hex from "crypto-js/enc-hex";
import Button from "../UI/buttons/Button";
import BasicModal from "../UI/modals/BasicModal";
import classes from "./MentorDashboard.module.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Overlay, Popover } from "react-bootstrap";
import SetCalendar from "./onSched/SetCalendar";
import SetCompanyStage from "../signUp/SetCompanyStage";
import ShareProfile from "../modals/ShareProfile";
import UpdateExpertise from "../modals/UpdateExpertise";
import UpdateBio from "../modals/UpdateBio";
import UpdatePrice from "../modals/UpdatePrice";
// import Withdraw from "../modals/Withdraw";
import SelectPaymentMethod from "../modals/SelectPaymentMethod";
import MetaData from "../../utils/MetaData";
import SyncCalendar from "../modals/SyncCalendar";
import AccBalance from "./AccBalance";

const MentorDashboardAdmin = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [stageLoading, setStageLoading] = useState(false);
  const [getPaidLoading, setGetPaidLoading] = useState(false);
  const [availabilityShow, setAvailabilityShow] = useState(false);
  const [shareShow, setShareShow] = useState(false);
  const [syncCalendarShow, setSyncCalendarShow] = useState(false);
  const [stageShow, setStageShow] = useState(false);
  // const [withdrawShow, setWithdrawShow] = useState(false);
  const [imgUrl, setImgUrl] = useState(null);
  const [imgLoading, setImgLoading] = useState(false);
  const [priceShow, setPriceShow] = useState(false);
  const [accountIncomplete, setAccountIncomplete] = useState(false);
  const [expertiseShow, setExpertiseShow] = useState(false);
  const [getPaidShow, setGetPaidShow] = useState(false);
  const [bioShow, setBioShow] = useState(false);
  const [show, setShow] = useState(false);
  const [timeoutShow, setTimeoutShow] = useState(false);
  let [searchParams] = useSearchParams();
  const [calendar, setCalendar] = useState(false);
  const ref = useRef();
  const profileImageRef = useRef();
  const [mentor, setMentor] = useState(null);
  const [target, setTarget] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    async function loadPage() {
      await axios(`https://oddience.herokuapp.com/api/v1/admin/mentor`, {
        method: "get",
        headers: { token: window.localStorage.getItem("token") },
      })
        .then((response) => {
          window.sessionStorage.setItem(
            "details",
            JSON.stringify({
              lastName: response.data.mentor.name.split(" ")[1],
              uniqueID: response.data.mentor.uniqueID,
            })
          );
          setMentor(response.data.mentor);

          if (response.data.mentor.availability) {
            setCalendar(true);
          }

          if (
            response.data.mentor.account_id &&
            !response.data.mentor.account_complete
          ) {
            setAccountIncomplete(true);
          }
          setPageLoading(false);
          setTimeout(() => {
            if (!response.data.mentor.availability) {
              const button = document.getElementById("update-profile");
              button.click();
            }
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
          setPageLoading(false);
          if (err.response.data.message === "jwt expired") {
            setTimeoutShow(true);
          } else if (
            err.response.data.message ===
            "You must be logged in to access this feature"
          ) {
            setTimeoutShow(true);
          } else {
            setTimeoutShow(true);
          }
        });
    }
    loadPage();
  }, [searchParams]);

  const closeAvailModal = (close) => {
    setAvailabilityShow(!close);
  };

  const closeSkillModal = () => {
    setExpertiseShow(false);
  };

  const closeBioModal = () => {
    setExpertiseShow(false);
  };

  const onSubmitStage = async (stage) => {
    setStageLoading(true);

    await axios
      .post(
        `https://oddience.herokuapp.com/api/v1/mentor/company-stage`,
        {
          stage,
        },
        {
          headers: {
            token: window.localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        setStageLoading(false);
        setStageShow(false);
      })
      .catch((err) => {
        setStageLoading(false);
        console.log(err.response.data.message);
      });
    // console.log(response);
  };

  const imgChangeHandle = async (e) => {
    setImgLoading(true);
    const timestamp = Date.now();
    const details = JSON.parse(window.sessionStorage.getItem("details"));

    let signature = await sha256(
      `eager=w_400,h_300,c_pad&folder=images/profile&public_id=${details.uniqueID}-profile-picture&timestamp=${timestamp}7lVKOqOaaF8W5kH7hKnnuzCe2Ho`
    );

    signature = Hex.stringify(signature);

    var file = e.target.files[0];
    // let baseURL;
    var reader = new FileReader();
    reader.onloadend = function () {
      // baseURL = reader.result;
    };

    await reader.readAsDataURL(file);

    setTimeout(async () => {
      await axios
        .post("https://api.cloudinary.com/v1_1/oddience/image/upload", {
          file: reader.result,
          eager: "w_400,h_300,c_pad",
          public_id: `${details.uniqueID}-profile-picture`,
          api_key: "474715537984353",
          signature,
          folder: "images/profile",
          timestamp,
        })
        .then(async (res) => {
          // setLoading(false)
          setImgUrl(res.data.url);
          await axios
            .put(
              `https://oddience.herokuapp.com/api/v1/mentor/profile/update/?image=${true}`,
              {
                url: res.data.url,
              },
              {
                headers: {
                  token: window.localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              window.location.reload();
            })
            .catch((err) => {
              setImgLoading(false);
              console.log(err);
              console.log(err.response.data.message);
            });
        })
        .catch((err) => {
          // setLoading(false)
          console.log(err.response);
        });
    }, 1000);
  };

  const getPaid = async (e) => {
    setGetPaidLoading(true);
    await axios
      .get("https://oddience.herokuapp.com/api/v1/mentor/stripe/connect", {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setGetPaidLoading(false);
        setGetPaidShow(false);
        window.location.replace(response.data.accountLink.url);
      })
      .catch((error) => {
        setGetPaidLoading(false);
        console.log(error);
        console.log(error.response.data);
      });
  };

  return (
    <Fragment>
      {pageLoading ? (
        <div className={classes["page-loading"]}>
          <img
            src={`${process.env.PUBLIC_URL}/static/images/header-logo.png`}
            alt="Page loading indicator"
          ></img>
        </div>
      ) : mentor ? (
        <div
          className={classes["container-main"]}
          style={{ marginTop: "5rem" }}
        >
          <MetaData title={`${mentor.name}'s Dashboard`} />

          <div
            style={{ justifyContent: "space-between" }}
            className={classes.logout}
          >
            <Button
              styles={{ width: "100%", textAlign: "left", paddingLeft: "2rem" }}
              variant="plain"
              type={"button"}
              text="Share profile link"
              divStyles={{ margin: "0", width: "50%" }}
              onClick={() => setShareShow(true)}
            />
            <Button
              text={
                <span
                  style={{ margin: "0", justifyContent: "end" }}
                  className={classes.logout}
                >
                  Logout<i className="fal fa-sign-out-alt ms-2"></i>
                </span>
              }
              type="button"
              styles={{ width: "100%" }}
              onClick={(event) => {
                window.sessionStorage.clear();
                window.localStorage.clear();
                setTimeout(() => {
                  navigate("/coach/login");
                }, 1000);
              }}
              divStyles={{ margin: "0", width: "35%" }}
              variant="plain"
            />
          </div>
          <div>
            <input
              onChange={imgChangeHandle}
              ref={profileImageRef}
              style={{ display: "none" }}
              type={"file"}
            />
            <div className={classes["profile-img-container"]}>
              {imgLoading ? (
                <i className="loading-spinner text-secondary fas fa-circle-notch"></i>
              ) : mentor.imageUrl ? (
                <img
                  className={classes["profile-image"]}
                  src={mentor.imageUrl}
                  alt="profile"
                />
              ) : imgUrl ? (
                <img
                  className={classes["profile-image"]}
                  src={imgUrl}
                  alt="profile"
                />
              ) : (
                <i className="fal fa-user"></i>
              )}
            </div>
            <Button
              // id={"update-profile"}
              text={
                <i
                  style={{ color: "#21274F", fontSize: "1rem" }}
                  className="fad fa-edit"
                ></i>
              }
              type="button"
              variant="plain"
              divStyles={{
                position: "relative",
                top: "-20px",
                right: "-125px",
                width: "fit-content",
                // display: "inline",
                margin: "0",
              }}
              styles={{ width: "fit-content" }}
              onClick={(event) => {
                profileImageRef.current.click();
              }}
            />
          </div>
          <h1 className={classes["dashboard-heading"]}>{mentor.name}</h1>
          <div className={classes.block}>
            <span
              style={{ display: "flex", alignItems: "center" }}
              className={classes["big-text"]}
            >
              &#36;
              {String(mentor.pricePerSesh).indexOf(".") < 0
                ? String(mentor.pricePerSesh) + ".00"
                : String(mentor.pricePerSesh)}{" "}
              <Button
                text={
                  <i
                    style={{ color: "#21274F", fontSize: "1rem" }}
                    className="fad fa-edit"
                  ></i>
                }
                type="button"
                variant="plain"
                divStyles={{
                  width: "fit-content",
                  display: "inline",
                  margin: "0",
                }}
                styles={{ width: "100%" }}
                onClick={() => {
                  setPriceShow(true);
                }}
              />
            </span>
            <span className={classes["small-text"]}>Price per session</span>
          </div>
          <div className={classes.block}>
            <span className={classes["big-text"]}>30 mins</span>
            <span className={classes["small-text"]}>Session length</span>
          </div>
          <div className={classes.block}>
            <span className={classes["big-text"]}>{mentor.bookings}</span>
            <span className={classes["small-text"]}>Number of sessions</span>
          </div>
          <div ref={ref} style={{ width: "50%" }}>
            <Button
              id={"update-profile"}
              text="Update Profile"
              type="button"
              styles={{ width: "100%", marginTop: "3rem" }}
              onClick={(event) => {
                setShow(!show);
                setTarget(event.target);
              }}
            />

            <Overlay
              target={target}
              // placement={"top"}
              container={ref}
              show={show}
            >
              <Popover id={`popover`}>
                <Popover.Header as="h3">Update Profile</Popover.Header>
                <Popover.Body>
                  <div>
                    <div>
                      <Button
                        text={
                          calendar ? (
                            "Update Availability"
                          ) : (
                            <span>
                              <i
                                style={{ fontSize: "0.97rem" }}
                                className="fad text-danger me-3 fa-exclamation-circle"
                              ></i>
                              Set Availability
                            </span>
                          )
                        }
                        type="button"
                        variant="plain"
                        styles={{ width: "100%" }}
                        divStyles={{ margin: "0 0 1.5rem 0" }}
                        onClick={(event) => {
                          setAvailabilityShow(!availabilityShow);
                          setShow(false);
                        }}
                      />
                    </div>
                    <Button
                      text={
                        mentor.stage ? (
                          "Update Company Stage"
                        ) : (
                          <span>
                            <i
                              style={{ fontSize: "0.97rem" }}
                              className="fad text-danger me-3 fa-exclamation-circle"
                            ></i>
                            Choose Company Stage
                          </span>
                        )
                      }
                      type="button"
                      variant="plain"
                      styles={{ width: "100%" }}
                      divStyles={{ margin: "0 0 1.5rem 0" }}
                      onClick={(event) => {
                        setShow(!show);
                        setStageShow(true);
                      }}
                    />

                    <Button
                      text={
                        <span>
                          {!mentor.account_id ||
                            !mentor.account_complete ||
                            (!mentor.usingFlutterwave && (
                              <i
                                style={{ fontSize: "0.97rem" }}
                                className="fad text-danger me-3 fa-exclamation-circle"
                              ></i>
                            ))}
                          {mentor.account_id || mentor.account_complete
                            ? "Change Payment Method"
                            : mentor.usingPaystack
                            ? "Change Payment Method"
                            : "Get Paid"}
                        </span>
                      }
                      type="button"
                      // loading={getPaidLoading}
                      variant="plain"
                      styles={{ width: "100%" }}
                      divStyles={{ margin: "0 0 1.5rem 0" }}
                      onClick={() => {
                        setGetPaidShow(true);
                        setShow(false);
                      }}
                    />

                    {!mentor.googleCalendarAuthorized &&
                    !mentor.outlookCalendarAuthorized ? (
                      <Button
                        text={"Sync Calendar"}
                        type="button"
                        // loading={getPaidLoading}
                        variant="plain"
                        styles={{ width: "100%" }}
                        divStyles={{ margin: "0 0 1.5rem 0" }}
                        onClick={() => {
                          setSyncCalendarShow(true);
                          setShow(false);
                        }}
                      />
                    ) : (
                      <Button
                        text={"Sync Another Calendar"}
                        type="button"
                        // loading={getPaidLoading}
                        variant="plain"
                        styles={{ width: "100%" }}
                        divStyles={{ margin: "0 0 1.5rem 0" }}
                        onClick={() => {
                          setSyncCalendarShow(true);
                          setShow(false);
                        }}
                      />
                    )}
                  </div>
                </Popover.Body>
              </Popover>
            </Overlay>
          </div>
          <div>
            <a
              href="https://t.me/+NlNJy5t1cK01Njlk"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                text={
                  <>
                    Please join our community
                    <i
                      style={{ marginLeft: "0.5rem" }}
                      className="fas fa-external-link-alt"
                    ></i>
                  </>
                }
                type="button"
                variant="plain"
                styles={{ width: "100%" }}
                divStyles={{ margin: "1rem 0" }}
              />
            </a>
          </div>
          {mentor.usingPaystack && (
            <AccBalance
              accountDetails={mentor.paystackBankDetails}
              classes={classes}
              changeAccountDetails={() => setGetPaidShow(true)}
            />
          )}
          <div className={classes.bio}>
            <h3
              style={{ display: "flex", alignItems: "center" }}
              className={classes["block-heading"]}
            >
              Bio{" "}
              <Button
                // id={"update-profile"}
                text={
                  <i
                    style={{ color: "#21274F", fontSize: "1rem" }}
                    className="fad fa-edit"
                  ></i>
                }
                type="button"
                variant="plain"
                divStyles={{
                  width: "fit-content",
                  display: "inline",
                  margin: "0",
                }}
                styles={{ width: "100%" }}
                onClick={(event) => {
                  setBioShow(true);
                }}
              />
            </h3>
            <p>{mentor.bio}</p>
          </div>
          <div className={classes.bio}>
            <h3
              style={{ display: "flex", alignItems: "center" }}
              className={classes["block-heading"]}
            >
              Expertise{" "}
              <Button
                // id={"update-profile"}
                text={
                  <i
                    style={{ color: "#21274F", fontSize: "1rem" }}
                    className="fad fa-edit"
                  ></i>
                }
                type="button"
                variant="plain"
                divStyles={{
                  width: "fit-content",
                  display: "inline",
                  margin: "0",
                }}
                styles={{ width: "100%" }}
                onClick={(event) => {
                  setExpertiseShow(true);
                }}
              />
            </h3>
            <p>
              {mentor.skills.length > 0 ? (
                mentor.skills.map((skill, i) => {
                  return (
                    <Fragment key={i}>
                      <span>{skill.label}</span>
                      {i < mentor.skills.length - 1 && <br />}
                    </Fragment>
                  );
                })
              ) : (
                <span>
                  No approved skills to show. The skill you added is still
                  awaiting approval
                </span>
              )}
            </p>
          </div>
          <div className={classes.bio}>
            <h3
              style={{ display: "flex", alignItems: "center" }}
              className={classes["block-heading"]}
            >
              Best Fit For{" "}
              <Button
                // id={"update-profile"}
                text={
                  <i
                    style={{ color: "#21274F", fontSize: "1rem" }}
                    className="fad fa-edit"
                  ></i>
                }
                type="button"
                variant="plain"
                divStyles={{
                  width: "fit-content",
                  display: "inline",
                  margin: "0",
                }}
                styles={{ width: "100%" }}
                onClick={(event) => {
                  setStageShow(true);
                }}
              />
            </h3>
            <p>{mentor.stage ? mentor.stage.label : ""}</p>
          </div>

          {mentor.googleCalendarAuthorized ||
          mentor.outlookCalendarAuthorized ? (
            <div className={classes.bio}>
              <h3
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "1rem",
                }}
                className={classes["block-heading"]}
              >
                Calendars{" "}
              </h3>
              {mentor.googleCalendarAuthorized && (
                <div className={classes.calendar}>
                  <i className="fas fa-calendar-alt"></i>
                  <div>
                    <span>Google</span>
                    <span>{mentor.googleCalendarId}</span>
                  </div>
                  {/* <Button
                    text={<i className="fas fa-trash-alt" style={{fontSize: '1rem', color: 'inherit'}}></i>}
                    type="button"
                    variant="plain"
                    styles={{ width: "100%" }}
                    divStyles={{ margin: "0 0 1.5rem 0" }}
                    // onClick={() => {
                    //   setSelected(false);
                    // }}
                  /> */}
                </div>
              )}
              {mentor.outlookCalendarAuthorized && (
                <div className={classes.calendar}>
                  <i className="fas fa-calendar-alt"></i>
                  <div>
                    <span>Outlook</span>
                    <span>{mentor.outlookCalendarId}</span>
                  </div>
                  {/* <Button
                    text={<i className="fas fa-trash-alt" style={{fontSize: '1rem', color: 'inherit'}}></i>}
                    type="button"
                    variant="plain"
                    styles={{ width: "100%" }}
                    divStyles={{ margin: "0", width: "fit-content"}}
                    // onClick={() => {
                    //   setSelected(false);
                    // }}
                  /> */}
                </div>
              )}
            </div>
          ) : (
            ""
          )}
          {mentor.loginUrl && (
            <a href={mentor.loginUrl} target="_blank" rel="noreferrer">
              <Button
                // id={"update-profile"}
                text={"View Stripe Dashboard"}
                type="button"
                variant="primary"
                divStyles={{
                  width: "fit-content",
                  display: "inline",
                  margin: "0",
                }}
                styles={{ width: "100%" }}
                // onClick={(event) => {
                //   setStageShow(true);
                // }}
              />
            </a>
          )}

          <BasicModal
            show={availabilityShow}
            title={`Set Calendar`}
            closeButton={true}
            close={() => setAvailabilityShow(false)}
            body={
              <SetCalendar
                prevAvailability={
                  mentor.availability ? mentor.availability : null
                }
                close={closeAvailModal}
              />
            }
          />
          <BasicModal
            show={shareShow}
            title={`Share Profile`}
            closeButton={true}
            close={() => setShareShow(false)}
            body={<ShareProfile username={mentor.username} />}
          />
          <BasicModal
            show={stageShow}
            title={mentor.stage ? "Update Company Stage" : `Set Company Stage`}
            closeButton={true}
            close={() => setStageShow(false)}
            body={
              <SetCompanyStage
                loading={stageLoading}
                onSubmit={onSubmitStage}
              />
            }
          />
          <BasicModal
            show={expertiseShow}
            title={"Update Expertise"}
            closeButton={true}
            close={() => setExpertiseShow(false)}
            body={
              <UpdateExpertise
                close={closeSkillModal}
                prevSkills={mentor.skills}
              />
            }
          />
          <BasicModal
            show={bioShow}
            title={"Update Bio"}
            closeButton={true}
            close={() => setBioShow(false)}
            body={<UpdateBio close={closeBioModal} prevBio={mentor.bio} />}
          />
          <BasicModal
            show={accountIncomplete}
            title={"Bank Account Details Incomplete"}
            closeButton={true}
            close={() => setAccountIncomplete(false)}
            body={
              <Fragment>
                <p>
                  Your last attempt at adding your payment details to stripe
                  failed. Please click the button below to try again.
                </p>
                <Button
                  text={<span>Get Paid</span>}
                  type="button"
                  loading={getPaidLoading}
                  variant="primary"
                  styles={{ width: "100%" }}
                  divStyles={{ margin: "0 0 1.5rem 0" }}
                  onClick={getPaid}
                />
              </Fragment>
            }
          />
          <BasicModal
            show={priceShow}
            title={`Update Price Per Session`}
            closeButton={true}
            close={() => setPriceShow(false)}
            body={
              <UpdatePrice
                close={() => setPriceShow(false)}
                prevPrice={mentor.pricePerSesh}
              />
            }
          />
          <BasicModal
            show={getPaidShow}
            title={`Get Paid`}
            closeButton={true}
            close={() => setGetPaidShow(false)}
            body={
              <SelectPaymentMethod
                usingPaystack={mentor.usingPaystack}
                getPaidStripe={getPaid}
              />
            }
          />
          {/* <BasicModal
            show={withdrawShow}
            title={`Withdraw funds`}
            closeButton={true}
            close={() => setWithdrawShow(false)}
            body={
              <Withdraw
                accBal={
                  mentor.flutterwaveBankDetails.accBalance &&
                  Number(mentor.flutterwaveBankDetails.accBalance.toFixed(2))
                }
              />
            }
          /> */}
          <BasicModal
            show={syncCalendarShow}
            title={`Sync Calendars`}
            closeButton={true}
            close={() => setSyncCalendarShow(false)}
            body={<SyncCalendar />}
          />
        </div>
      ) : timeoutShow ? (
        <BasicModal
          show={timeoutShow}
          title="Login Expired"
          closeButton={true}
          close={() => setStageShow(false)}
          body={
            <div>
              <p>
                Your session has expired. You will need to login again to access
                your dashboard
              </p>

              <Button
                text="Login"
                type="button"
                // variant="plain"
                styles={{ width: "100%" }}
                divStyles={{ margin: "0" }}
                onClick={() => {
                  navigate("/coach/login");
                }}
              />
            </div>
          }
        />
      ) : (
        <div style={{ height: "78vh" }}></div>
      )}
    </Fragment>
  );
};

export default MentorDashboardAdmin;
