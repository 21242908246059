import React, { Fragment, useState } from "react";
import axios from "axios";
import Input from "../inputs/Input";
import Button from "../UI/buttons/Button";
import classes from "./SelectPaymentMethod.module.css";
import styles from "../inputs/Input.module.css";

const SyncCalendar = () => {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({
    calendar: null,
    selected: false,
  });
  const [email, setEmail] = useState("");

  const syncGoogleHandle = () => {
    setSelected({
      calendar: "Google",
      selected: true,
    });
  };

  const syncOutlookHandle = () => {
    setSelected({
      calendar: "Outlook",
      selected: true,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    let body;

    if (selected.calendar === "Google") {
      body = {
        googleCalendarId: email,
      };
    }

    if (selected.calendar === "Outlook") {
      body = {
        outlookCalendarId: email,
      };
    }

    setLoading(true);

    await axios
      .put(
        `https://oddience.herokuapp.com/api/v1//mentor/calendar/sync`,
        {
          calendar: selected.calendar,
          ...body,
        },
        {
          headers: {
            token: window.localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        window.location.replace(response.data.url);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response.data.message);
      });
  };

  return (
    <div>
      <p>
        You can sync your external calendars so you do not miss any
        appointments.
      </p>
      {!selected.selected ? (
        <Fragment>
          <div className={classes["payment-container"]}>
            <Button
              styles={{
                width: "100%",
                textAlign: "center",
                height: "fit-content",
                cursor: loading ? "default" : "pointer",
              }}
              variant="plain"
              type={"button"}
              text={
                <img
                  src={`${process.env.PUBLIC_URL}/static/images/google-calendar.jpg`}
                  alt="google calendar logo"
                  style={{
                    width: "80%",
                  }}
                  className={`${classes["payment-portal-logo"]} ${
                    loading ? classes["loading"] : ""
                  }`}
                />
              }
              divStyles={{ margin: "0", marginTop: "1rem", width: "100%" }}
              onClick={syncGoogleHandle}
              disabled={loading}
            />

            {/* <p style={{ fontSize: "0.75rem" }}>For mentors in Africa</p> */}
          </div>
          <div className={classes["payment-container"]}>
            <Button
              styles={{
                width: "100%",
                textAlign: "center",
                height: "fit-content",
                cursor: loading ? "default" : "pointer",
              }}
              variant="plain"
              type={"button"}
              text={
                <img
                  src={`${process.env.PUBLIC_URL}/static/images/outlook-calendar.png`}
                  alt="outlook calendar logo"
                  style={{
                    width: "100%",
                  }}
                  className={`${classes["payment-portal-logo"]} ${
                    loading ? classes["loading"] : ""
                  }`}
                />
              }
              divStyles={{ margin: "0", width: "100%" }}
              onClick={syncOutlookHandle}
              disabled={loading}
            />

            {/* <p style={{ fontSize: "0.75rem", marginTop: "1.3rem" }}>
          For mentors outside Africa
        </p> */}
          </div>
        </Fragment>
      ) : (
        <div>
          <form onSubmit={handleFormSubmit}>
            <Input
              classes={styles["form-control"]}
              styleExt={{ marginBottom: "0" }}
              type="email"
              disabled={false}
              placeholder={`Enter your ${selected.calendar} email address`}
              onChange={(e) => setEmail(e.target.value)}
              required={true}
              value={email}
            />
            <p style={{ fontSize: "0.8rem" }}>
              You will be redirected to Onsched to sync your calendars
            </p>
            <Button text={"Sync Calendar"} type="submit" loading={loading} />
          </form>
          <Button
            text={"Cancel"}
            type="button"
            variant="plain"
            styles={{ width: "100%" }}
            divStyles={{ margin: "0 0 1.5rem 0" }}
            onClick={() => {
              setSelected(false);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default SyncCalendar;
