import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import CurrencyInput from "react-currency-input-field";
// import Select from "react-select";
import Creatable from "react-select/creatable";
import MetaData from "../../utils/MetaData";
import classes from "./MentorSignUp.module.css";
import styles from "../inputs/Input.module.css";
import Button from "../UI/buttons/Button";
// import skills from "../../utils/skills";
import { useNavigate } from "react-router-dom";

const GetToKnow = () => {
  const [skillsSelected, setSkillsSelected] = useState(null);
  const [pricePerSesh, setPricePerSesh] = useState(10.99);
  const [skills, setSkills] = useState([]);
  const [skillIds, setSkillIds] = useState([]);
  const [savedSkills] = useState(
    JSON.parse(window.localStorage.getItem("details")).skills
  );
  const [savedPrice] = useState(
    JSON.parse(window.localStorage.getItem("details")).pricePerSesh
  );
  const navigate = useNavigate();

  useEffect(() => {
    const getSkills = async () => {
      await axios
        .get("https://oddience.herokuapp.com/api/v1/skills?filter=active")
        .then((response) => {
          setSkills(response.data.formSkills);
          setSkillIds(response.data.skillIds);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getSkills();
    if (savedSkills) {
      setSkillsSelected(
        JSON.parse(window.localStorage.getItem("details")).skills
      );
    }
    if (savedPrice) {
      setPricePerSesh(
        JSON.parse(window.localStorage.getItem("details")).pricePerSesh
      );
    }
  }, [savedSkills, savedPrice]);

  const handlePriceBlur = (e) => {
    let value = e.target.value;
    if (value.length > 4) {
      value = value.split(",").join("");
    }
    if (value.indexOf(".") === -1) {
      setPricePerSesh(value.slice(1) + ".00");
    } else if (
      value.indexOf(".") !== value.length - 1 &&
      value.slice(value.indexOf(".") + 1).length !== 2
    ) {
      setPricePerSesh(value.slice(1) + "0");
    } else if (value[value.length - 1] === ".") {
      setPricePerSesh(value.slice(1) + "00");
    }
  };

  const handleSetPrice = (value, name) => {
    if (!value) {
      setPricePerSesh(0);
    } else if (value[0] === "0" && !value.includes(".")) {
      setPricePerSesh(Number(String(value).slice(1)));
    } else {
      setPricePerSesh(value);
    }
  };

  const createSkillHandler = async (option) => {
    const optionEntered = {
      value: option,
      label: option,
      new: true,
    };
    setSkillsSelected((prevSkillsSelected) => {
      return [...prevSkillsSelected, optionEntered];
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();

    const details = JSON.parse(window.localStorage.getItem("details"));

    const selectedSkillIds = skillsSelected.map((skill) => {
      if (skill.new) {
        return skill;
      } else {
        return skillIds[skill.index];
      }
    });

    window.localStorage.setItem(
      "details",
      JSON.stringify({
        ...details,
        skills: skillsSelected,
        skillIds: selectedSkillIds,
        pricePerSesh,
      })
    );

    setTimeout(() => {
      navigate("/coach/sign-up/create-password");
    }, 500);
  };
  return (
    <Fragment>
      <MetaData title={"You're almost done"} />

      <Button
        text={
          <span>
            <i className="fas fa-arrow-left"></i> Back
          </span>
        }
        type="button"
        variant="plain"
        onClick={() => navigate("/coach/sign-up/get-to-know")}
      />
      {/* <CSSTransition classNames={"slide-in"} timeout={500} in={inProp}> */}
      <main className={`${classes["sign-up-main"]} ${classes.wide}`}>
        <h1>Almost there!</h1>
        <form className={classes.form} onSubmit={submitHandler}>
          <p
            style={{
              fontSize: "0.875rem",
              color: "#21274F",
              margin: "1.5rem 0",
            }}
          >
            How much do you charge per session?
          </p>
          <CurrencyInput
            intlConfig={{ locale: "en-US", currency: "USD" }}
            id="price-per-sesh"
            placeholder="$10.99"
            defaultValue={10.99}
            value={pricePerSesh}
            decimalsLimit={2}
            onBlur={handlePriceBlur}
            onValueChange={handleSetPrice}
            className={styles["form-control"]}
          />
          <p
            style={{
              fontSize: "0.875rem",
              color: "#21274F",
              margin: "0 0 1.5rem 0 ",
            }}
          >
            What topics do you have expertise in?
          </p>
          <Creatable
            defaultValue={skillsSelected}
            onChange={setSkillsSelected}
            onCreateOption={createSkillHandler}
            options={skills}
            isMulti={true}
            className={`${styles["form-select"]} ${styles["expertise"]}`}
            // isDisabled={allSkills.length >= 5}
            isSearchable={true}
            value={skillsSelected}
            required
          />
          <p style={{ fontSize: "0.7rem", marginBottom: "1rem" }}>
            (If you can'd find what you're looking for. You can add it and we'd
            review. If accepted it'll be added to your profile and our
            database.)
          </p>
          <Button text={"Proceed"} type="submit" />
        </form>
      </main>
      {/* </CSSTransition> */}
    </Fragment>
  );
};

export default GetToKnow;
