import React, { useState } from "react";
import axios from "axios";
import Button from "../UI/buttons/Button";
import styles from "../inputs/Input.module.css";
import Input from "../inputs/Input";

const UpdateBio = ({ prevBio, close }) => {
  const [bio, setBio] = useState(prevBio ? prevBio : "");
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    await axios
      .put(
        `https://oddience.herokuapp.com/api/v1/mentor/profile/update?bio=${true}`,
        {
          bio,
        },
        {
          headers: {
            token: window.localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        setLoading(false);
        close();
        // console.log(response);
        window.location.reload();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response.data.message);
      });
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <Input
        classes={`${styles["form-control"]} ${styles["textarea"]}`}
        type="textarea"
        disabled={false}
        placeholder="Bio"
        onChange={(e) => setBio(e.target.value)}
        required={true}
        value={bio}
      />
      <Button loading={loading} text={"Update"} type="submit" />
    </form>
  );
};

export default UpdateBio;
