import React from 'react'

const PaymentSuccessful = () => {
  return (
    <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
        <i style={{fontSize: '10rem', color: 'green', marginTop: '1rem'}} className="fas fa-check-circle"></i>
        <p style={{textAlign: 'center', marginTop: '2rem'}}>Your appointment has been successfully reserved. Check your email for more details</p>
    </div>
  )
}

export default PaymentSuccessful