import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../UI/buttons/Button";

const Logout = () => {
  const navigate = useNavigate();
  const logoutHandler = () => {
    window.localStorage.clear();
    navigate("/admin/login");
  };

  return (
    <Button
      text={
        <>
          <i
            style={{ marginRight: "0.5rem" }}
            className="fas fa-sign-out-alt"
          ></i>
          Logout
        </>
      }
      type="button"
      variant="plain"
      onClick={logoutHandler}
      styles={{ width: "fit-content" }}
      divStyles={{ marginLeft: "1rem" }}
    />
  );
};

export default Logout;
