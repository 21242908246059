import React, { Fragment, useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Input from "../inputs/Input";
import SignUpWithLinkedIn from "../../utils/SignUpWithLinkedIn";
import MetaData from "../../utils/MetaData";
import classes from "./MentorSignUp.module.css";
import styles from "../inputs/Input.module.css";
import Button from "../UI/buttons/Button";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";

const MentorSignUp = () => {
  const [loading, setLoading] = useState(false);
  const [linkedInLoading, setLinkedInLoading] = useState(true);
  let [searchParams] = useSearchParams();
  const [savedDetails] = useState(() => {
    if (JSON.parse(window.localStorage.getItem("details"))) {
      return JSON.parse(window.localStorage.getItem("details"));
    } else {
      return {
        email: "",
        bioData: {
          firstName: "",
          lastName: "",
          bio: "",
        },
        skills: [],
      };
    }
  });
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (savedDetails.email) {
      setEmail(savedDetails.email);
    }

    if (searchParams.get("code")) {
      const getLinkedInDetails = async () => {
        window.localStorage.setItem("token", "beiuwboq20230000b)_b0-ei0efb08r");
        await axios
          .post(`https://oddience.herokuapp.com/api/v1/mentor/linkedIn`, {
            code: searchParams.get("code"),
          })
          .then(async (response) => {
            // console.log(response);
            const { email, firstName, lastName, linkedInId, profileImageUrl } =
              response.data.data;
            window.localStorage.setItem(
              "details",
              JSON.stringify({
                ...savedDetails,
                email,
                bioData: {
                  firstName,
                  lastName,
                },
                linkedInId,
                profileImageUrl,
              })
            );
            setTimeout(() => {
              navigate("/coach/sign-up/get-to-know");
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
          });
      };

      getLinkedInDetails();
    } else {
      setLinkedInLoading(false);
    }
  }, [navigate, savedDetails, searchParams]);

  const submitHandler = async (e) => {
    setLoading(true);
    e.preventDefault();

    await axios
      .post(`https://oddience.herokuapp.com/api/v1/user/check/email`, {
        email,
      })
      .then(async (response) => {
        window.localStorage.setItem(
          "details",
          JSON.stringify({ ...savedDetails, email })
        );

        await window.localStorage.setItem(
          "token",
          "beiuwboq20230000b)_b0-ei0efb08r"
        );

        await window.sessionStorage.setItem("params", "here");

        navigate("/coach/sign-up/get-to-know");
      })
      .catch((err) => {
        if (err.response.data.message) {
          console.log(err.response.data);
          setLoading(false);
          return toast.error(err.response.data.message);
        }
      });
  };
  return (
    <Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
      />
      <MetaData
        title={linkedInLoading ? "Loading..." : "Welcome to Oddience"}
      />
      {linkedInLoading ? (
        <div className={classes["page-loading"]}>
          <img
            src={`${process.env.PUBLIC_URL}/static/images/header-logo.png`}
            alt="Page loading indicator"
          ></img>
        </div>
      ) : (
        <main className={`${classes["sign-up-main"]}  ${classes.home}`}>
          <h1>Get Your Mentor Profile</h1>
          <div style={{ margin: "5rem 0 1rem 0" }}>
            {/* Sign up with LinkedIn */}
            <SignUpWithLinkedIn />
          </div>
          <p
            style={{
              fontSize: "0.875rem",
              color: "#21274F",
              margin: "1.5rem 0",
            }}
          >
            Or sign up with email
          </p>
          <form className={classes.form} onSubmit={submitHandler}>
            <Input
              classes={styles["form-control"]}
              type="email"
              disabled={false}
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              required={true}
              value={email}
            />
            <Button text={"Complete Set Up"} type="submit" loading={loading} />
            <p
              style={{
                fontSize: "0.875rem",
                color: "#21274F",
                margin: "2rem 0 0 0",
              }}
            >
              Already have an account?{" "}
              <Link style={{ color: "#03B1D8" }} to="/coach/login">
                Sign in.
              </Link>
            </p>
          </form>
        </main>
      )}
      {/* </CSSTransition> */}
    </Fragment>
  );
};

export default MentorSignUp;
