import React, { useState, useEffect, Fragment } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Creatable from "react-select/creatable";
import axios from "axios";
import Button from "../UI/buttons/Button";
import styles from "../inputs/Input.module.css";

const UpdateExpertise = ({ prevSkills, close }) => {
  const [skillsSelected, setSkillsSelected] = useState(
    prevSkills ? prevSkills : null
  );
  const [newOption, setNewOption] = useState(null);
  const [loading, setLoading] = useState(false);
  const [skills, setSkills] = useState([]);
  const [skillIds, setSkillIds] = useState([]);

  useEffect(() => {
    const getSkills = async () => {
      await axios
        .get("https://oddience.herokuapp.com/api/v1/skills?filter=active")
        .then((response) => {
          setSkills(response.data.formSkills);
          setSkillIds(response.data.skillIds);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getSkills();
  }, []);

  const handleFormSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    const selectedSkillIds = skillsSelected.map((skill) => {
      if (Object.keys(skill).indexOf("new") > 0) {
        return skill;
      } else if (Object.keys(skill).indexOf("index") < 0) {
        return skillIds[
          skills.filter((skill2) => {
            return skill2.value === skill.value;
          })[0].index
        ];
      } else {
        return skillIds[skill.index];
      }
    });

    console.log(selectedSkillIds);

    await axios
      .put(
        `https://oddience.herokuapp.com/api/v1/mentor/profile/update?skills=${true}`,
        {
          skills: selectedSkillIds,
        },
        {
          headers: {
            token: window.localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        if (newOption) {
          toast(
            "Topic has been added for review. You will be notified on it's status soon"
          );
        }
        setLoading(false);
        close();
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response.data.message);
      });
  };

  const createSkillHandler = async (option) => {
    const optionEntered = {
      value: option,
      label: option,
      new: true,
    };
    if (skillsSelected.includes(optionEntered)) {
      return toast.error("Duplicate skill detected!");
    }
    setNewOption(optionEntered);
    setSkillsSelected((prevSkillsSelected) => {
      return [...prevSkillsSelected, optionEntered];
    });
  };

  return (
    <Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
      />
      <form onSubmit={handleFormSubmit}>
        <p
          style={{
            fontSize: "0.875rem",
            color: "#21274F",
            margin: "1.5rem 0",
          }}
        >
          What topics do you have expertise in?
        </p>
        <Creatable
          defaultValue={skillsSelected}
          onChange={(option) => {
            setSkillsSelected(option);
            console.log(option);
            console.log(skills);
          }}
          onCreateOption={createSkillHandler}
          options={skills}
          isMulti={true}
          className={`${styles["form-select"]} ${styles["expertise"]}`}
          // isDisabled={allSkills.length >= 5}
          isSearchable={true}
          allow
          value={skillsSelected}
          required
        />
        <p style={{ fontSize: "0.7rem", marginBottom: "1rem" }}>
          (If you can'd find what you're looking for. You can add it and we'd
          review. If accepted it'll be added to your profile and our database.)
        </p>

        <Button loading={loading} text={"Update"} type="submit" />
      </form>
    </Fragment>
  );
};

export default UpdateExpertise;
