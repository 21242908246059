import React, { Fragment, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Input from "../inputs/Input";
import classes from "./MentorSignUp.module.css";
import styles from "../inputs/Input.module.css";
import Button from "../UI/buttons/Button";
import BasicModal from "../UI/modals/BasicModal";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [forgotPwd, setForgotPwd] = useState(false);
  const [email, setEmail] = useState("");
  const [forgotEmail, setForgotEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const submitHandler = async (e) => {
    setLoading(true);
    e.preventDefault();

    await axios
      .post(`https://oddience.herokuapp.com/api/v1/mentor/login`, {
        email,
        password,
      })
      .then((response) => {
        window.localStorage.setItem("token", response.data.token);

        setTimeout(() => {
          navigate(`/coach/dashboard`);
        }, 500);
      })
      .catch((err) => {
        if (err.response.data.message) {
          setLoading(false);
          return toast.error(err.response.data.message);
        }
      });
  };

  const resetPassword = async () => {
    setLoading(true);

    await axios
      .post(`https://oddience.herokuapp.com/api/v1/password/forgot`, {
        email: forgotEmail,
      })
      .then((response) => {
        setLoading(false);
        setForgotPwd(false);
        return toast(
          "Check your email for further instructions to reset your password"
        );
      })
      .catch((err) => {
        if (err.response.data.message) {
          setLoading(false);
          return toast.error(err.response.data.message);
        }
      });
  };
  return (
    <Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
      />

      <main className={`${classes["sign-up-main"]}  ${classes.home}`}>
        <h1 style={{ marginBottom: "4rem" }}>Coach Login</h1>
        <form className={classes.form} onSubmit={submitHandler}>
          <Input
            classes={styles["form-control"]}
            type="email"
            disabled={false}
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            required={true}
            value={email}
          />
          <Input
            classes={styles["form-control"]}
            type="password"
            disabled={false}
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
            required={true}
            value={password}
          />
          <Button text={"Sign in"} type="submit" loading={loading} />
        </form>
        <Button
          styles={{ width: "100%" }}
          variant="plain"
          type={"button"}
          text="Forgot password?"
          divStyles={{ margin: "1rem 0 0 0", width: "50%" }}
          onClick={() => setForgotPwd(true)}
        />
        <BasicModal
          centered={true}
          show={forgotPwd}
          title={`Forgot Password`}
          closeButton={true}
          close={() => setForgotPwd(false)}
          body={
            <div>
              <Input
                classes={styles["form-control"]}
                type="email"
                disabled={false}
                placeholder="Enter your mail"
                onChange={(e) => setForgotEmail(e.target.value)}
                required={true}
                value={forgotEmail}
              />
              <Button
                text={"Reset password"}
                type="button"
                loading={loading}
                onClick={resetPassword}
              />
            </div>
          }
        />
      </main>
    </Fragment>
  );
};

export default Login;
