import React from "react";
import styles from "../inputs/Input.module.css";

const Input = ({
  id,
  type,
  classes,
  onChange,
  disabled,
  placeholder,
  required,
  children,
  onClick,
  value,
  styleExt,
  autoComplete,
  ...rest
}) => {
  return type === "textarea" ? (
    <textarea
      className={classes}
      onChange={onChange ? onChange : null}
      disabled={disabled}
      placeholder={placeholder}
      required={required}
      autoComplete={autoComplete}
      value={value}
      {...rest}
    ></textarea>
  ) : children ? (
    <div className={styles["input-container"]} style={styleExt}>
      <input
        id={id ? id : null}
        type={type}
        className={styles.input}
        onChange={onChange ? onChange : null}
        disabled={disabled}
        placeholder={placeholder}
        required={required}
        autoComplete={autoComplete}
        {...rest}
      />
      <button
        tabIndex={"-1"}
        onClick={onClick}
        className={styles["input-button"]}
        type="button"
      >
        {children}
      </button>
    </div>
  ) : (
    <input
      id={id ? id : null}
      style={styleExt ? styleExt : {}}
      value={value}
      type={type}
      className={classes}
      onChange={onChange ? onChange : null}
      disabled={disabled}
      placeholder={placeholder}
      autoComplete={autoComplete}
      required={required}
      {...rest}
    />
  );
};

export default Input;
