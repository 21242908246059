import React, { Fragment, useEffect, useState } from "react";
import classes from "./Button.module.css";

const Button = ({
  text,
  type,
  styles,
  divStyles,
  onClick,
  loading,
  variant,
  id,
  disabled,
  ...rest
}) => {
  const [load, setLoad] = useState(loading);

  useEffect(() => {
    setLoad(loading);
  }, [loading]);

  return (
    <Fragment>
      {variant ? (
        <div
          style={
            variant === "plain"
              ? { width: "100%", marginTop: "5rem", ...divStyles }
              : { width: "100%", ...divStyles }
          }
        >
          <button
            id={id ? id : null}
            className={`${classes.button} ${classes[variant]}`}
            style={styles && styles}
            onClick={onClick ? onClick : null}
            type={type}
            disabled={disabled ? disabled : load}
            {...rest}
          >
            {load ? (
              <i className="loading-spinner text-light fas fa-circle-notch"></i>
            ) : (
              text
            )}
          </button>
        </div>
      ) : (
        <button
          id={id ? id : null}
          className={classes.button}
          style={styles && styles}
          onClick={onClick ? onClick : null}
          disabled={disabled ? disabled : load}
          type={type}
          {...rest}
        >
          {load ? (
            <i className="loading-spinner text-light fas fa-circle-notch"></i>
          ) : (
            text
          )}
        </button>
      )}
    </Fragment>
  );
};

export default Button;
